import { Fragment, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';
import { createEmptyServicePackage } from 'entities/ServicePackage/ServicePackageUtils';
import ServicePacakgeForm from 'pages/ServicePackage/ServicePackageForm';
import IServicePackage from 'entities/ServicePackage/IServicePackage';

const ServicePackageEdit = () => {
  const { servicePackageId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [servicePackage, setServicePackage] = useState<IServicePackage>(createEmptyServicePackage());
  const fetchedData = !!servicePackage.ServicePackageId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    ServicePackageService.get(servicePackageId)
      .then((servicePackage) => {
        setServicePackage(servicePackage);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePackageId]);

  return fetchedData ? <ServicePacakgeForm servicePackage={servicePackage} readonly={readonly} /> : <Fragment />;
};

export default ServicePackageEdit;
