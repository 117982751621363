import { useParams } from 'react-router-dom';
import RoutingTreeListView from 'pages/RoutingTree/RoutingTreeListView';

const RoutingTreeListAll = () => {
  const { applicationId = '' } = useParams();

  return <RoutingTreeListView applicationId={applicationId} />;
};

export default RoutingTreeListAll;
