import { FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Path, FieldValues, Control } from 'react-hook-form/dist/types';

interface IProps<T extends FieldValues> {
  field: Path<T>;
  control: Control<T>;
  label?: string;
  readonly?: boolean;
}

const BooleanField = <T extends FieldValues>(props: IProps<T>) => {
  const { field, label: optional_label, control, readonly } = props;
  const label = optional_label ?? field;

  return (
    <Controller
      name={field}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          control={<Switch disabled={readonly} color='secondary' {...field} checked={field.value} />}
          label={label}
          labelPlacement='start'
        />
      )}
    />
  );
};

export default BooleanField;
