/* eslint-disable no-template-curly-in-string */

import { AnyObject, Maybe, array, string, lazy, ISchema, boolean, number } from 'yup';
import { parseISO, isValid, isEqual, isAfter } from 'date-fns';

const DATE_REGEX = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z|)$/;

const isValidUrl = (value: string | undefined): boolean => {
  if (value) {
    try {
      return new URL(value) && true;
    } catch {
      return false;
    }
  }

  return true;
};

export const guid = string()
  .defined()
  .length(36)
  .matches(/[0-9A-Fa-f]{8}(-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}/);

export default abstract class EntitySchema<T extends Maybe<AnyObject>> {
  //=============================================
  // constraints
  //=============================================

  // string
  protected empty = string().defined().length(0);
  protected name = string().defined().min(1, '${path} must be at least 1 character').max(40);
  protected description = string().optional().min(0).max(255);
  protected url = string().min(0).max(255).test('is-url', '${path} must be a valid URL', isValidUrl);

  // identifiers
  protected guid = guid;

  // TODO: refine validation
  // - must be empty for "new"
  // - must be guid for "existing"
  protected identifier: ISchema<string> = lazy((value) => (!!value ? this.guid : this.empty));

  // boolean
  protected boolean = boolean().defined();

  // number
  protected integer = number().integer().defined().typeError("${path} must be of type 'integer'");

  // special: validFromDate
  protected validFromDate = string().matches(DATE_REGEX, 'Invalid Date');

  // special: validToDate
  protected validToDate = string()
    .optional()
    .matches(DATE_REGEX, 'Invalid Date')
    .test('date-range-test', "'to' date must be after 'from' date", (value, context) => {
      const from = context.parent.ValidFrom as string;
      const to = value as string;
      const from_date = parseISO(from);
      const to_date = parseISO(to);

      // only check if both are valid dates
      if (isValid(from_date) && isValid(to_date)) {
        return isEqual(to_date, from_date) || isAfter(to_date, from_date);
      }

      // skip test if one of the dates is invalid
      return true;
    });

  //=============================================
  // public functions
  //=============================================

  abstract getSchema(): ISchema<T>;

  getArraySchema(): ISchema<T[]> {
    const entiySchema = this.getSchema();
    return array().of(entiySchema).required();
  }

  getDynamicSchema(): ISchema<T | T[]> {
    return lazy((value) => {
      if (Array.isArray(value)) {
        return this.getArraySchema();
      }
      return this.getSchema();
    });
  }
}
