import { Route, Routes } from 'react-router-dom';
import CustomerNodeList from './CustomerNodeList';
import CustomerNodeCreate from './CustomerNodeCreate';
import CustomerNodeEdit from './CustomerNodeEdit';
import Dictionary from 'routes/Dictionary/Dictionary';

const CustomerNode = () => {
  return (
    <Routes>
      <Route index element={<CustomerNodeList />} />
      <Route path='create' element={<CustomerNodeCreate />} />
      <Route path=':customerNodeId' element={<CustomerNodeEdit />} />
      <Route path=':customerNodeId/dictionaries/*' element={<Dictionary />} />
    </Routes>
  );
};

export default CustomerNode;
