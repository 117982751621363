import { Route, Routes } from 'react-router-dom';
import SubContractList from './SubContractList';
import SubContractCreate from './SubContractCreate';
import SubContractEdit from './SubContractEdit';
import Application from 'routes/Application/Application';
import CacheConfiguration from 'routes/CacheConfiguration/CacheConfiguration';
import ServicePackage from 'routes/ServicePackage/ServicePackage';

const SubContract = () => {
  return (
    <Routes>
      <Route index element={<SubContractList />} />
      <Route path='create' element={<SubContractCreate />} />
      <Route path=':subContractId' element={<SubContractEdit />} />
      <Route path=':subContractId/applications/*' element={<Application />} />
      <Route path=':subContractId/cache-configurations/*' element={<CacheConfiguration />} />
      <Route path=':subContractId/service-packages/*' element={<ServicePackage />} />
    </Routes>
  );
};

export default SubContract;
