import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { CacheConfigurationService } from 'entities/CacheConfiguration/CacheConfigurationService';
import CacheConfigurationForm from 'pages/CacheConfiguration/CacheConfigurationForm';
import ICacheConfiguration from 'entities/CacheConfiguration/ICacheConfiguration';

const CacheConfigurationCreateFromDefault = () => {
  const { subContractId = '', cacheConfigurationId: cacheName = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [cacheConfiguration, setCacheConfiguration] = useState<ICacheConfiguration | undefined>();
  const fetchedData = !!cacheConfiguration?.CacheName;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    const service = new CacheConfigurationService(subContractId);

    service
      .getAll()
      .then((data) => {
        const cacheConfiguration = data.find((item) => item.CacheName === cacheName);

        if (cacheConfiguration) {
          cacheConfiguration.CacheConfigurationId = '';
        }

        setCacheConfiguration(cacheConfiguration);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subContractId, cacheName]);

  return fetchedData ? (
    <CacheConfigurationForm subContractId={subContractId} readonly={readonly} cacheConfiguration={cacheConfiguration} />
  ) : (
    <React.Fragment />
  );
};

export default CacheConfigurationCreateFromDefault;
