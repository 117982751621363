import { useParams } from 'react-router-dom';
import { createEmptyRoutingTree } from 'entities/RoutingTree/RoutingTreeUtils';
import RoutingTreeForm from 'pages/RoutingTree/RoutingTreeForm';

const RoutingTreeCreate = () => {
  const { applicationId = '' } = useParams();
  const emptyRoutingTree = createEmptyRoutingTree();

  return <RoutingTreeForm applicationId={applicationId} routingTree={emptyRoutingTree} />;
};

export default RoutingTreeCreate;
