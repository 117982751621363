import { Fragment, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { CustomerNodeService } from 'entities/CustomerNode/CustomerNodeService';
import { createEmptyCustomerNode } from 'entities/CustomerNode/CustomerNodeUtils';
import CustomerNodeForm from 'pages/CustomerNode/CustomerNodeForm';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';

const CustomerNodeEdit = () => {
  const { customerNodeId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [customerNode, setCustomerNode] = useState<ICustomerNode>(createEmptyCustomerNode());
  const fetchedData = !!customerNode.NodeId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    CustomerNodeService.get(customerNodeId)
      .then((customerNode) => {
        setCustomerNode(customerNode);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNodeId]);

  return fetchedData ? <CustomerNodeForm customerNode={customerNode} readonly={readonly} /> : <Fragment />;
};

export default CustomerNodeEdit;
