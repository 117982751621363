import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { Path, FieldValues, Control } from 'react-hook-form/dist/types';

interface IProps<T extends FieldValues> {
  field: Path<T>;
  control: Control<T>;
  label?: string;
  readonly?: boolean;
}

const NumberField = <T extends FieldValues>(props: IProps<T>) => {
  const { field, label: optional_label, control, readonly = false } = props;
  const id = field.toString();
  const label = optional_label ?? field;

  return (
    <Controller
      name={field}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          id={id}
          {...field}
          required
          label={label}
          type='number'
          InputProps={{ inputProps: { min: 0, readOnly: readonly } }}
          fullWidth
          margin='dense'
          error={error ? true : false}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default NumberField;
