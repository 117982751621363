import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import IMuiAutocomplete from './IMuiAutocomplete';

const MuiAutocomplete = (props: IMuiAutocomplete) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { name }, fieldState: { error } }) => (
        <Autocomplete
          id={props.id}
          options={props.options}
          getOptionLabel={props.getOptionLabel}
          value={props.value}
          readOnly={props.disabled}
          onChange={props.onChange}
          renderInput={(params: any) => (
            <TextField
              {...params}
              name={name}
              label={props.label}
              variant='outlined'
              readOnly={props.disabled}
              error={!!error}
              helperText={error ? error.message : ''}
            />
          )}
        />
      )}
    />
  );
};

export default MuiAutocomplete;
