import { useState } from 'react';
import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro';

export default function useFilterModel(): [GridFilterModel, (item: GridFilterItem) => void] {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const setFilterModelItem = (item: GridFilterItem) => {
    const id = item.id;
    const items = filterModel.items;
    const currentItem = items.find((item) => item.id === id);

    if (currentItem) {
      const index = items.indexOf(currentItem);
      items[index] = item;
    } else {
      filterModel.items.push(item);
    }

    setFilterModel({ ...filterModel });
  };

  return [filterModel, setFilterModelItem];
}
