import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateOptions } from 'utils';
import ISubContract from 'entities/SubContract/ISubContract';
import IServicePackage from 'entities/ServicePackage/IServicePackage';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';
import { SubContractService } from 'entities/SubContract/SubContractService';
import { ServiceTypeService } from 'entities/ServiceType/ServiceTypeService';
import IServiceType from 'entities/ServiceType/IServiceType';
import IProperties from 'entities/ServiceType/IServiceTypeProperty';
import { Typography, Stack, Grid, Box } from '@mui/material';
import ServicePackageSchema from 'entities/ServicePackage/ServicePackageSchema';
import { AnyObjectSchema } from 'yup';
import MuiAutocomplete from 'components/Autocomplete/MuiAutocomplete';
import { useLoader, useSnackBar } from 'context';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

interface IServicePackageForm {
  servicePackage: IServicePackage;
  readonly?: boolean;
}

const columns: GridColDef[] = [
  { field: 'Key', type: 'string', flex: 1 },
  { field: 'Description', type: 'string', flex: 2 },
  {
    field: 'Encrypted',
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
    flex: 1
  },
  {
    field: 'Mandatory',
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
    flex: 1
  }
];

const schema = new ServicePackageSchema().getSchema();

const ServicePacakgeForm = (props: IServicePackageForm) => {
  const { servicePackage, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const [typeCodes, setServiceTypes] = useState<Array<IServiceType>>([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState<Array<Record<'lable' | 'value' | 'id', string>>>([]);
  const [selectedService, setSelectedService] = useState<IServiceType>();
  const [showServiceTypeCodes, setShowServiceTypeCodes] = useState<boolean>(false);
  const [subContractAsOptions, setSubContractAsOptions] = useState<Record<'value' | 'name', string>[]>([]);
  const { showSnackBar } = useSnackBar();
  const { loader } = useLoader();
  const update = !!servicePackage.ServicePackageId;

  const saveServicePackage = (servicePackage: IServicePackage) => {
    const action = update ? 'updated' : 'created';

    loader(
      ServicePackageService.save(servicePackage)
        .then((servicePackage) => {
          showSnackBar(`${servicePackage.ServicePackageId} ${action} successfully.`, 'success');
          navigateBack();
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const listSubContracts = () => {
    // loader(
    SubContractService.getAll()
      .then((data) => {
        if (data && Array.isArray(data)) {
          getSubContractOptions(data);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // );
  };

  const listServiceTypes = useCallback(() => {
    loader(
      ServiceTypeService.getAll()
        .then((serviceTypes) => {
          const options = generateOptions('Code', serviceTypes);

          setServiceTypes(serviceTypes);

          if (options.length) {
            setServiceTypeOptions(options);
          }
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader]);

  const handleAsyncSubmit = (servicePackage: IServicePackage) => {
    saveServicePackage(servicePackage);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { isValid }
  } = useForm<IServicePackage>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: servicePackage
  });

  const getSubContractOptions = (subcontracts: ISubContract[]) => {
    const options = subcontracts.map((subcontract) => ({
      value: subcontract.SubContractId,
      name: subcontract.SubContractName
    }));
    return setSubContractAsOptions(options);
  };

  useEffect(() => {
    if (subContractAsOptions.length < 1 || !subContractAsOptions) {
      listSubContracts();
    }

    if (!serviceTypeOptions.length) {
      listServiceTypes();
    }

    if ((serviceTypeOptions?.length && servicePackage.ServiceTypeCode) || selectedService) {
      if (selectedService?.Properties?.length) {
        setShowServiceTypeCodes(true);
      } else if (!selectedService && servicePackage.ServiceTypeCode) {
        const type = typeCodes.filter((type) => type.Code === servicePackage.ServiceTypeCode)[0];
        setSelectedService(type);
        setShowServiceTypeCodes(true);
      }
    }
    return () => {
      setShowServiceTypeCodes(false);
    };
    //eslint-disable-next-line
  }, [
    subContractAsOptions,
    listServiceTypes,
    serviceTypeOptions,
    selectedService,
    typeCodes,
    servicePackage.ServiceTypeCode
  ]);

  const handleSubContractChange = (event: any, newValue: Record<'value' | 'name', string> | null) => {
    const selectedSubContractId = newValue ? newValue.value : '';
    setValue('SubContractId', selectedSubContractId ?? '', { shouldValidate: true });
    subContractRegister.onChange(event);
  };

  const handleServiceTypeCodeChange = (event: any, newValue: Record<'value' | 'lable' | 'id', string> | null) => {
    const selectedServiceType = newValue ? newValue.value : '';
    setValue('ServiceTypeCode', selectedServiceType ?? '', { shouldValidate: true });
    const selectedItem = typeCodes.filter((type) => type.Code === selectedServiceType)[0];
    setSelectedService(selectedItem);
    setShowServiceTypeCodes(true);
    serviceTypeRegister.onChange(event);
  };

  const subContractRegister = register('SubContractId', { required: true });
  const serviceTypeRegister = register('ServiceTypeCode', { required: true });

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form onSubmit={handleSubmit((values: IServicePackage) => handleAsyncSubmit(values))}>
        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
            {update ? (readonly ? 'View' : 'Update') : 'Create'} Service Package
          </Typography>

          <MuiAutocomplete
            register={register}
            name='SubContractId'
            control={control}
            id='SubContractId'
            options={subContractAsOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(subcontract: any) => subcontract.name}
            value={subContractAsOptions.find((subcontract) => subcontract.value === getValues('SubContractId')) || null}
            disabled={servicePackage.SubContractId ? true : false}
            label='Sub Contract *'
            onChange={handleSubContractChange}
          />

          <MuiTextField field='Description' control={control} readonly={readonly} />

          <MuiAutocomplete
            register={register}
            name='ServiceTypeCode'
            control={control}
            id='ServiceTypeCode'
            options={serviceTypeOptions.sort((a, b) => a.lable.localeCompare(b.lable))}
            getOptionLabel={(serviceType: any) => serviceType.lable}
            value={serviceTypeOptions.find((serviceType) => serviceType.value === getValues('ServiceTypeCode')) || null}
            disabled={servicePackage.ServiceTypeCode ? true : false}
            onChange={handleServiceTypeCodeChange}
            label='Service Type *'
          />

          {showServiceTypeCodes && (
            <Grid item xs={12} sm={12} sx={{ height: 400, width: '100%' }}>
              <DataGridPro
                columns={columns}
                rows={selectedService?.Properties ?? []}
                getRowId={(row: IProperties) => row.Key}
                pagination
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } }
                }}
                pageSizeOptions={[
                  5,
                  10,
                  25
                ]}
              />{' '}
            </Grid>
          )}

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default ServicePacakgeForm;
