import ICustomerNode from './ICustomerNode';

export const createEmptyCustomerNode = (): ICustomerNode => {
  return {
    NodeId: '',
    ServicePackageId: '',
    Active: true,
    DTAPClass: 'dev'
  };
};
