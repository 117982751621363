import EntitySchema from 'entities/EntitySchema';
import { array, object, ObjectSchema, Schema, string } from 'yup';
import ICustomerNode from './ICustomerNode';

export default class CustomerNodeSchema extends EntitySchema<ICustomerNode> {
  // TODO: change dtapClass to number.
  // private dtapClass = number().defined().min(0).max(3);
  private dtapClass = string().defined();

  private properties = array(
    object({
      Key: string().defined(),
      Value: string().defined()
    })
  ).defined();

  override getSchema(): Schema<ICustomerNode> {
    const schema: ObjectSchema<ICustomerNode> = object({
      NodeId: this.identifier,
      ServicePackageId: this.guid,
      Active: this.boolean,
      Description: this.description,
      DTAPClass: this.dtapClass,
      URI: this.url.optional(),
      Properties: this.properties,
      ValidFrom: this.validFromDate.optional(),
      ValidTo: this.validToDate
    });

    return schema;
  }
}
