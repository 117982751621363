import { useParams } from 'react-router-dom';
import ServicePackageListView from 'pages/ServicePackage/ServicePackageListView';

const ServicePackageList = () => {
  const { subContractId = '' } = useParams();

  return <ServicePackageListView subContractId={subContractId} />;
};

export default ServicePackageList;
