import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';
import ICustomerNode from './ICustomerNode';

//==============================================================
// private variables
//==============================================================

const get = (path: string) => Server.get(path).then((data) => data?.CustomerNodes);

//==============================================================
// CustomerNode EntityService
//==============================================================

class CustomerNodeService extends EntityService<ICustomerNode> {
  getAll(): Promise<ICustomerNode[]> {
    return get(this.getPath());
  }

  getByServicePackageId(id: string): Promise<ICustomerNode[]> {
    return get(`${ServicePackageService.getPath()}/${id}/${this.getPath()}`);
  }

  getId(item: ICustomerNode): string {
    return item.NodeId;
  }

  getPath(): string {
    return '/customer-nodes';
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new CustomerNodeService();

export { instance as CustomerNodeService };
