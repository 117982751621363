import { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IDictionary from 'entities/Dictionary/IDictionary';
import IDictionaryDefinition from 'entities/DictionaryDefinition/IDictionaryDefinition';
import { IDictionaryDefinitionParameters } from 'entities/DictionaryDefinition/IDictionaryDefinitionParameters';
import { IDictionaryParameters } from 'entities/Dictionary/IDictionaryParameters';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPro,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridEditMode
} from '@mui/x-data-grid-pro';
import { DictionaryService } from 'entities/Dictionary/DictionaryService';
import { useSnackBar } from 'context';
import {
  NewRow,
  convertToInOutboundEntryParameters,
  flattenParameters,
  generateColumns,
  modifyDictionaryParameterNames,
  validateDictionary
} from './DictionaryHelper';
import useNavigateBack from 'hooks/useNavigateBack';

interface IDictionaryFormProps {
  customerNodeId: string;
  definition: IDictionaryDefinition;
  dictionary: IDictionary;
  readonly?: boolean;
}

const DictionaryForm = (props: IDictionaryFormProps) => {
  const { showSnackBar } = useSnackBar();
  const navigateBack = useNavigateBack();
  const { customerNodeId, definition, dictionary, readonly = false } = props;
  const isValid = true;
  const modifiedDictionary = modifyDictionaryParameterNames(dictionary);

  // InBound Operations
  const inboundEntryParametersColumns: IDictionaryDefinitionParameters | undefined = definition?.InboundEntryParameters;
  const flattenedInboundData = inboundEntryParametersColumns
    ? modifiedDictionary?.InboundEntryParameters
      ? flattenParameters(modifiedDictionary?.InboundEntryParameters)
      : []
    : [];

  const modifiedInboundData: GridRowsProp = inboundEntryParametersColumns
    ? flattenedInboundData.map((row: any, index: any) => ({
        id: index,
        ...row
      }))
    : [];

  const [inboundRows, setInboundRows] = useState(modifiedInboundData);
  const [inboundRowModesModel, setInboundRowModesModel] = useState<GridRowModesModel>({});

  interface EditInboundToolbarProps {
    setInboundRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setInboundRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  }

  function EditInboundToolbar(props: EditInboundToolbarProps) {
    const { setInboundRows, setInboundRowModesModel } = props;

    const handleClick = () => {
      const nextIndex = inboundRows.length; // Calculate the next index
      const id = nextIndex; // Convert it to a string if needed

      // Initialize the new row with empty values for each column
      const newRow: NewRow = { id };

      inboundColumns.forEach((column: { field: string }) => {
        newRow[column.field as string] = '';
      });

      setInboundRows((oldRows) => [...oldRows, newRow]);
      setInboundRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit } // Set the first column to focus
      }));
    };

    return (
      <GridToolbarContainer
        sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(0, 0, 90)', color: 'white' }}>
        {!readonly && (
          <Button startIcon={<AddIcon />} onClick={handleClick} sx={{ color: 'white' }}>
            Add Record
          </Button>
        )}
        <Typography sx={{ fontSize: 18, fontWeight: 500, textAlign: 'center', flex: 1 }}>
          InboundEntryParameters DataGrid
        </Typography>
      </GridToolbarContainer>
    );
  }

  const handleInboundRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleInboundEditClick = (id: GridRowId) => () => {
    setInboundRowModesModel({ ...inboundRowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleInboundSaveClick = (id: GridRowId) => () => {
    setInboundRowModesModel({ ...inboundRowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleInboundDeleteClick = (id: GridRowId) => () => {
    setInboundRows(inboundRows.filter((row) => row['id'] !== id));
  };

  const handleInboundCancelClick = (id: GridRowId) => () => {
    setInboundRowModesModel({
      ...inboundRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = inboundRows.find((row) => row['id'] === id);
    if (editedRow!['isNew']) {
      setInboundRows(inboundRows.filter((row) => row['id'] !== id));
    }
  };

  const processInboundRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setInboundRows(inboundRows.map((row) => (row['id'] === newRow['id'] ? updatedRow : row)));
    return updatedRow;
  };

  const handleInboundRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setInboundRowModesModel(newRowModesModel);
  };

  const inboundColumns = inboundEntryParametersColumns
    ? generateColumns(
        inboundEntryParametersColumns,
        handleInboundSaveClick,
        handleInboundCancelClick,
        handleInboundEditClick,
        handleInboundDeleteClick,
        inboundRowModesModel,
        readonly
      )
    : [];

  // OutBound Operations
  const outboundEntryParametersColumns: IDictionaryDefinitionParameters | undefined =
    definition?.OutboundEntryParameters;
  const flattenedOutboundData = outboundEntryParametersColumns
    ? modifiedDictionary?.OutboundEntryParameters
      ? flattenParameters(modifiedDictionary?.OutboundEntryParameters)
      : []
    : [];

  const modifiedOutboundData: GridRowsProp = outboundEntryParametersColumns
    ? flattenedOutboundData.map((row: any, index: any) => ({ id: index, ...row }))
    : [];

  const [outboundRows, setOutboundRows] = useState(modifiedOutboundData);
  const [outboundRowModesModel, setOutboundRowModesModel] = useState<GridRowModesModel>({});

  const handleOutboundRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleOutboundEditClick = (id: GridRowId) => () => {
    setOutboundRowModesModel({ ...outboundRowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleOutboundSaveClick = (id: GridRowId) => () => {
    setOutboundRowModesModel({ ...outboundRowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleOutboundDeleteClick = (id: GridRowId) => () => {
    setOutboundRows(outboundRows.filter((row) => row['id'] !== id));
  };

  const handleOutboundCancelClick = (id: GridRowId) => () => {
    setOutboundRowModesModel({
      ...outboundRowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = outboundRows.find((row) => row['id'] === id);
    if (editedRow!['isNew']) {
      setOutboundRows(outboundRows.filter((row) => row['id'] !== id));
    }
  };

  const processOutboundRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setOutboundRows(outboundRows.map((row) => (row['id'] === newRow['id'] ? updatedRow : row)));
    return updatedRow;
  };

  const handleOutboundRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setOutboundRowModesModel(newRowModesModel);
  };

  const outboundColumns = outboundEntryParametersColumns
    ? generateColumns(
        outboundEntryParametersColumns,
        handleOutboundSaveClick,
        handleOutboundCancelClick,
        handleOutboundEditClick,
        handleOutboundDeleteClick,
        outboundRowModesModel,
        readonly
      )
    : [];

  interface EditOutboundToolbarProps {
    setOutboundRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setOutboundRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  }

  function EditOutboundToolbar(props: EditOutboundToolbarProps) {
    const { setOutboundRows, setOutboundRowModesModel } = props;

    const handleClick = () => {
      const nextIndex = outboundRows.length;
      const id = nextIndex;

      const newRow: NewRow = { id };

      outboundColumns.forEach((column) => {
        newRow[column.field as string] = '';
      });

      setOutboundRows((oldRows) => [...oldRows, newRow]);
      setOutboundRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit }
      }));
    };

    return (
      <GridToolbarContainer
        sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(0, 0, 90)', color: 'white' }}>
        {!readonly && (
          <Button startIcon={<AddIcon />} onClick={handleClick} sx={{ color: 'white' }}>
            Add Record
          </Button>
        )}
        <Typography sx={{ fontSize: 18, fontWeight: 500, textAlign: 'center', flex: 1 }}>
          OutboundEntryParameters DataGrid
        </Typography>
      </GridToolbarContainer>
    );
  }

  const combineDataAndSave = () => {
    const inboundData = inboundEntryParametersColumns
      ? inboundRows.map((row) => {
          const { id, ...data } = row;
          return data;
        })
      : [];

    const outboundData = outboundEntryParametersColumns
      ? outboundRows.map((row) => {
          const { id, ...data } = row;
          return data;
        })
      : [];

    const filteredInboundDataArray = inboundEntryParametersColumns
      ? inboundData.map(({ isNew, actions, ...rest }) => rest)
      : [];
    const filteredOutboundDataArray = outboundEntryParametersColumns
      ? outboundData.map(({ isNew, actions, ...rest }) => rest)
      : [];

    const finalInboundEntryParameters: IDictionaryParameters[] = inboundEntryParametersColumns
      ? convertToInOutboundEntryParameters(filteredInboundDataArray)
      : [];

    const finalOutboundEntryParameters: IDictionaryParameters[] = outboundEntryParametersColumns
      ? convertToInOutboundEntryParameters(filteredOutboundDataArray)
      : [];

    dictionary.InboundEntryParameters = finalInboundEntryParameters;
    dictionary.OutboundEntryParameters = finalOutboundEntryParameters;

    const isDictionaryValid = validateDictionary(dictionary);

    if (isDictionaryValid) {
      const service = new DictionaryService(customerNodeId);
      service
        .save(dictionary)
        .then(() => {
          showSnackBar(`${dictionary.Name} dictionary is saved.`, 'success');
          navigateBack();
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    } else {
      showSnackBar('Every translation must have valid input and output params.', 'error');
    }
  };

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <Box
        sx={{
          height: 500,
          width: '95%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          },
          '& .inputColumn': {
            backgroundColor: '#e0ffe0',
            fontWeight: 'bold'
          },
          '& .outputColumn': {
            backgroundColor: '#fff0e0',
            fontWeight: 'bold'
          },
          '& .actionColumn': {
            backgroundColor: '#f0f0f0',
            fontWeight: 'bold'
          }
        }}>
        <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center', marginTop: '1rem', flex: 1 }}>
          {readonly ? 'View' : 'Update'} Dictionary
        </Typography>

        <Stack spacing={2} direction={'row'} sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <TextField
            id='DictionaryName'
            label='Dictionary Name *'
            fullWidth
            margin='dense'
            value={dictionary.Name}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            id='CustomerNodeId'
            label='Customer Node Id *'
            fullWidth
            margin='dense'
            value={customerNodeId}
            InputProps={{
              readOnly: true
            }}
          />
        </Stack>

        {inboundEntryParametersColumns ? (
          <DataGridPro
            rows={inboundRows}
            columns={inboundColumns}
            editMode={readonly ? ('view' as GridEditMode) : ('row' as GridEditMode)}
            rowModesModel={inboundRowModesModel}
            onRowModesModelChange={handleInboundRowModesModelChange}
            onRowEditStop={handleInboundRowEditStop}
            processRowUpdate={processInboundRowUpdate}
            slots={{
              toolbar: EditInboundToolbar
            }}
            slotProps={{
              toolbar: { setInboundRows, setInboundRowModesModel }
            }}
            sx={{
              marginTop: '1rem',
              boxShadow: 1,
              border: 1,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main'
              }
            }}
          />
        ) : (
          <Typography sx={{ fontSize: 18, fontWeight: 600, textAlign: 'center', marginTop: '1rem', flex: 1 }}>
            No schema is available for InboundEntryParameters DataGrid
          </Typography>
        )}

        {outboundEntryParametersColumns ? (
          <DataGridPro
            rows={outboundRows}
            columns={outboundColumns}
            editMode={readonly ? ('view' as GridEditMode) : ('row' as GridEditMode)}
            rowModesModel={outboundRowModesModel}
            onRowModesModelChange={handleOutboundRowModesModelChange}
            onRowEditStop={handleOutboundRowEditStop}
            processRowUpdate={processOutboundRowUpdate}
            slots={{
              toolbar: EditOutboundToolbar
            }}
            slotProps={{
              toolbar: { setOutboundRows, setOutboundRowModesModel }
            }}
            sx={{
              marginTop: '1rem',
              boxShadow: 1,
              border: 1,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main'
              }
            }}
          />
        ) : (
          <Typography sx={{ fontSize: 18, fontWeight: 600, textAlign: 'center', marginTop: '1rem', flex: 1 }}>
            No schema is available for OutboundEntryParameters DataGrid
          </Typography>
        )}

        <Stack spacing={2} direction={'row'} sx={{ marginTop: '1rem', marginBottom: '1rem', justifyContent: 'center' }}>
          <Button variant='contained' color='secondary' size='large' onClick={navigateBack} sx={{ width: 350 }}>
            {readonly ? 'Back' : 'CANCEL'}
          </Button>
          {!readonly && (
            <Button
              id={'save-dt'}
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              onClick={combineDataAndSave}
              disabled={!isValid}
              sx={{ width: 350 }}>
              Save
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default DictionaryForm;
