import { styled } from '@mui/material';

//===============================================
// styled syntax
//===============================================
export const DataGridContainer = styled('div')({
  width: '100%',
  height: '90%',
  margin: 0,
  padding: '30px 20px'
});

export const AddButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: 10,
  padding: 20
});

//===============================================
// MUI sx-property syntax
//===============================================
export const DataGridProSx = {
  '& .MuiDataGrid-columnHeaders': {
    color: 'white',
    bgcolor: 'primary.main',
    button: {
      color: 'white'
    }
  }
};
