import EntityService from 'entities/EntityService';
import IDictionary from './IDictionary';

//==============================================================
// Dictionary EntityService
//==============================================================

export class DictionaryService extends EntityService<IDictionary> {
  private customerNodeId: string;

  constructor(customerNodeId: string) {
    super();
    this.customerNodeId = customerNodeId;
  }

  getId(item: IDictionary): string {
    return item.Name;
  }

  getPath(): string {
    const customerNodeId = this.customerNodeId;
    return `/customer-nodes/${customerNodeId}/dictionary`;
  }

  //============================================================
  // unsupported methods
  //============================================================

  override getAll(): Promise<IDictionary[]> {
    // delete not supported for Dictionary
    throw new Error('Method not implemented.');
  }
}
