import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IRoutingComponent from './IRoutingComponent';

//==============================================================
// RoutingComponent EntityService
//==============================================================

class RoutingComponentService extends EntityService<IRoutingComponent> {
  getAll(): Promise<IRoutingComponent[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.RoutingComponents);
  }

  getId(item: IRoutingComponent): string {
    return item.RoutingComponentId;
  }

  getPath(): string {
    return '/routing-components';
  }

  //============================================================
  // unsupported methods
  //============================================================
  override delete(_id: string): Promise<IRoutingComponent> {
    // delete not supported for RoutingComponent
    throw new Error('Method not implemented.');
  }

  override get(_id: string): Promise<IRoutingComponent> {
    // get by id not supported for RoutingComponent
    throw new Error('Method not implemented.');
  }

  override save(_item: IRoutingComponent): Promise<IRoutingComponent> {
    // save not supported for RoutingComponent
    throw new Error('Method not implemented.');
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new RoutingComponentService();

export { instance as RoutingComponentService };
