import { useParams } from 'react-router-dom';
import { createEmptyApplication } from 'entities/Application/ApplicationUtils';
import ApplicationForm from 'pages/Application/ApplicationForm';

const ApplicationCreate = () => {
  const { contractId = '', subContractId = '' } = useParams();

  const application = {
    ...createEmptyApplication(),
    ...(!subContractId && !!contractId && { ContractId: contractId }),
    ...(!!subContractId && { SubContractId: subContractId })
  };

  return <ApplicationForm application={application} create={true} />;
};

export default ApplicationCreate;
