import { useEffect, useState, useMemo } from 'react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackBar, useDialog, useLoader } from 'context';
import EntityListView from 'components/EntityListView/EntityListView';
import { RoutingTreeService } from 'entities/RoutingTree/RoutingTreeService';
import { dateValueFormatter } from 'utils/DataGridUtils';
import IRoutingTree from 'entities/RoutingTree/IRoutingTree';

//===============================================
// Component props interface
//===============================================

interface IRoutingTreeListViewProps {
  applicationId: string;
}

//===============================================
// Component render function
//===============================================

const RoutingTreeListView = (props: IRoutingTreeListViewProps) => {
  const { applicationId } = props;
  const [data, setData] = useState<Array<IRoutingTree>>([]);
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listRoutingTrees = async () => {
    const service = new RoutingTreeService(applicationId);

    loader(
      service
        .getAll()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    const service = new RoutingTreeService(applicationId);

    service
      .delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
      .finally(() => {
        listRoutingTrees();
      });
  };

  const dialogDelete = (props: GridRowParams<IRoutingTree>) => {
    openDialog(
      'Delete Routing Tree',
      `You are about to remove ${props.row.RoutingTreeId}. This action is not reversible.`,
      () => {
        handleDelete(props.row.RoutingTreeId ?? '');
      }
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'RoutingTreeId', headerName: 'Routing Tree ID', type: 'string', flex: 2 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 3 },
      { field: 'MatchingType', headerName: 'MatchingType', type: 'string', flex: 2 },
      {
        field: 'ValidFrom',
        headerName: 'Valid From',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      },
      {
        field: 'ValidTo',
        headerName: 'Valid To',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      }
    ],
    []
  );

  useEffect(() => {
    listRoutingTrees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      getId={(row) => row.RoutingTreeId}
      deleteActionHandler={dialogDelete}
    />
  );
};

export default RoutingTreeListView;
