import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Box } from '@mui/material';
import { SubContractService } from 'entities/SubContract/SubContractService';
import { ContractService } from 'entities/Contract/ContractService';
import SubContractSchema from 'entities/SubContract/SubContractSchema';
import ISubContract from 'entities/SubContract/ISubContract';
import IContract from 'entities/Contract/IContract';
import { AnyObjectSchema } from 'yup';
import MuiDatePicker from 'components/DateRangePicker/MuiDatePicker';
import MuiAutocomplete from 'components/Autocomplete/MuiAutocomplete';
import { useSnackBar } from 'context';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

interface ISubContractForm {
  subContract: ISubContract;
  readonly?: boolean;
}

type ContractOption = Omit<IContract, 'Description' | 'Active'>;

const schema = new SubContractSchema().getSchema();

const SubContractForm = (props: ISubContractForm) => {
  const { subContract, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const [contractOptions, setContractOptions] = useState<ContractOption[]>([]);
  const [contractValidFrom, setContractValidFrom] = useState<string>('');
  const [contractValidTo, setContractValidTo] = useState<string>('');
  const { showSnackBar } = useSnackBar();
  const update = !!subContract.SubContractId;

  const onDateChangeHandler = (selectedDate: string, name: 'ValidTo' | 'ValidFrom') => {
    setValue(name, selectedDate, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<ISubContract> = (subContract) => {
    const action = update ? 'updated' : 'created';

    SubContractService.save(subContract)
      .then((result) => {
        showSnackBar(`${result.SubContractId} ${action} successfully.`, 'success');
        navigateBack();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { isValid }
  } = useForm<ISubContract>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: subContract
  });

  useEffect(() => {
    ContractService.getAll()
      .then((contracts) => {
        if (Array.isArray(contracts)) {
          const options: ContractOption[] = contracts.map((contract) => ({
            ContractId: contract.ContractId,
            ContractName: contract.ContractName,
            ValidFrom: contract.ValidFrom,
            ValidTo: contract.ValidTo
          }));
          trySetContractValidFrom(options, subContract.ContractId);
          setContractOptions(options);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subContract.ContractId]);

  const selectRegister = register('ContractId', { required: true });

  const trySetContractValidFrom = (options: ContractOption[], contractId: string) => {
    const selectedOption = options.find((option) => option.ContractId === contractId);
    if (selectedOption) {
      setContractValidFrom(selectedOption.ValidFrom ?? '');
      setContractValidTo(selectedOption.ValidTo ?? '');
    }
  };

  const contractChangeHandler = (event: any, newValue: ContractOption | null) => {
    const selectedContractId = newValue ? newValue.ContractId : '';
    setValue('ContractId', selectedContractId ?? '', { shouldValidate: true });
    trySetContractValidFrom(contractOptions, selectedContractId);
    selectRegister.onChange(event);
  };

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
          {update ? (readonly ? 'View' : 'Update') : 'Create'} Sub Contract
        </Typography>
        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <MuiAutocomplete
            register={register}
            name='ContractId'
            control={control}
            id='ContractId'
            options={contractOptions.sort((a, b) => a.ContractName.localeCompare(b.ContractName))}
            getOptionLabel={(option: any) => option.ContractName}
            value={contractOptions.find((contract) => contract.ContractId === getValues('ContractId')) || null}
            disabled={subContract.ContractId ? true : false}
            label='Contract *'
            onChange={contractChangeHandler}
          />

          <MuiTextField
            field='SubContractName'
            control={control}
            label={'Name'}
            readonly={readonly}
            isRequired={true}
          />

          <MuiTextField field='Description' control={control} readonly={readonly} />

          <Stack spacing={2} direction={'row'}>
            <MuiDatePicker
              name={'ValidFrom'}
              control={control}
              register={register}
              minDate={contractValidFrom}
              maxDate={contractValidTo}
              disabled={subContract.SubContractId ? true : false}
              onDateChange={onDateChangeHandler}
            />

            <MuiDatePicker
              name={'ValidTo'}
              control={control}
              register={register}
              minDate={contractValidFrom}
              maxDate={contractValidTo}
              disabled={subContract.SubContractId ? true : false}
              onDateChange={onDateChangeHandler}
            />
          </Stack>

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default SubContractForm;
