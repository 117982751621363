const emptyDictionaryDefinition = {
  Name: '',
  Description: '',
  InboundEntryParameters: {
    Inputs: [],
    Outputs: []
  },
  OutboundEntryParameters: {
    Inputs: [],
    Outputs: []
  }
};

export function createEmptyDictionaryDefinition() {
  return { ...emptyDictionaryDefinition };
}
