import { Fragment, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { ContractService } from 'entities/Contract/ContractService';
import { createEmptyContract } from 'entities/Contract/ContractsUtils';
import ContractForm from 'pages/Contract/ContractForm';
import IContract from 'entities/Contract/IContract';

const ContractEdit = () => {
  const { contractId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [contract, setContract] = useState<IContract>(createEmptyContract());
  const fetchedData = !!contract.ContractId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    ContractService.get(contractId)
      .then((contract) => {
        setContract(contract);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  return fetchedData ? <ContractForm contract={contract} readonly={readonly} /> : <Fragment />;
};

export default ContractEdit;
