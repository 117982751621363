import Sidebar from 'components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { Wrapper, StyledLayout } from './LayoutStyles';

type JSXNode = JSX.Element | null;

interface ILayout {
  children?: React.ReactNode;
}

const Layout = (props: ILayout): JSXNode => {
  const location = useLocation();
  const showNavBar = location.pathname !== '/' && !location.pathname.startsWith('/Dashboard');

  return (
    <Wrapper>
      {showNavBar ? <Sidebar /> : ''}
      <StyledLayout>{props.children}</StyledLayout>
    </Wrapper>
  );
};

export default Layout;
