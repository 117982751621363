import { formatDateForApi } from 'utils';
import IContract from './IContract';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

export const createEmptyContract = (): IContract => {
  const adapter = new AdapterDateFns();
  return {
    ContractId: '',
    Active: true,
    ContractName: '',
    ValidFrom: formatDateForApi(adapter.startOfDay(new Date()))
  };
};
