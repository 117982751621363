import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema, string, array, lazy } from 'yup';
import IRoutingTree from './IRoutingTree';

export default class RoutingTreeSchema extends EntitySchema<IRoutingTree> {
  private matchingType = string().defined().oneOf(['simple-matching', 'scope-based-matching']);
  private service = string().defined().min(1).max(64);
  private implementationVariant = string().defined().min(1).max(32);

  private properties = array(
    object({
      Key: string().defined().min(1).max(200),
      Value: string().defined().min(1, 'Routing Scope must be at least 1 character')
    })
  );

  private actions = array(
    object({
      ActionId: this.identifier,
      Service: this.service,
      Node: lazy(() => this.node.default(undefined))
    })
  );

  // TODO: replace any
  private node: any = object({
    RoutingTreeNodeId: this.guid.optional(),
    RoutingComponentId: this.guid,
    Service: this.service,
    ImplementationVariant: this.implementationVariant,
    Actions: this.actions,
    CustomerNodeId: this.guid.optional()
  });

  override getSchema(): ISchema<IRoutingTree> {
    const schema: ObjectSchema<IRoutingTree> = object({
      RoutingTreeId: this.identifier,
      MatchingType: this.matchingType,
      Description: this.description,
      Properties: this.properties,
      RootNode: this.node.optional(),
      ValidFrom: this.validFromDate.defined(),
      ValidTo: this.validToDate
    });

    return schema;
  }
}
