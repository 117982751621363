import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import { ContractService } from 'entities/Contract/ContractService';
import ISubContract from './ISubContract';
import RCIPError from 'entities/RCIPError';

//==============================================================
// private variables
//==============================================================

const get = (path: string) => Server.get(path).then((data) => data?.Subcontracts);

//==============================================================
// SubContract EntityService
//==============================================================

class SubContractService extends EntityService<ISubContract> {
  getAll(): Promise<ISubContract[]> {
    return get(this.getPath());
  }

  getByContractId(id: string): Promise<ISubContract[]> {
    return get(`${ContractService.getPath()}/${id}/${this.getPath()}`);
  }

  getId(item: ISubContract): string {
    return item.SubContractId;
  }

  getPath(): string {
    return '/subcontracts';
  }

  override errorHandler(original: RCIPError, id: string, item: ISubContract | null): RCIPError {
    const error = super.errorHandler(original, id, item);

    if (item && (error.code === '2207' || error.code === '2210')) {
      error.message = `${item.SubContractName} already exists.`;
    }

    return error;
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new SubContractService();

export { instance as SubContractService };
