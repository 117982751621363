import { useFormContext } from 'react-hook-form';
import { Box, Stack, Typography, TextField, Button } from '@mui/material';
import { IDetails } from './useDetails';
import { useEffect, useState } from 'react';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';
import { CustomerNodeService } from 'entities/CustomerNode/CustomerNodeService';
import { useLoader, useSnackBar } from 'context';
import IRoutingTree from 'entities/RoutingTree/IRoutingTree';
import { useStateContext } from 'pages/RoutingTree/StateContext';
import CustomerNodeSelectDialog from 'entities_ui/CustomerNode/CustomerNodeSelectDialog';
import SelectRoutingComponentDialog from 'entities_ui/RoutingComponent/RoutingComponentSelectDialog';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';
import { getActionsName, getIndexBasedName, getInstanceIndex, getServiceBasedName } from '../nameUtil';
import IRoutingTreeAction from 'entities/RoutingTree/IRoutingTreeAction';
import { DetailType } from './DetailType';
import { getDtapDisplayValue } from 'entities_ui/CustomerNode/CustomerNodeColumnDefs';

const Header = (props: { text: string }) => (
  <Typography fontSize='18px' fontWeight='700'>
    {props.text}
  </Typography>
);

const Text = (props: { label: string; value: string | undefined }) => (
  <TextField label={props.label} fullWidth margin='dense' value={props.value ? props.value : ' '} />
);

interface IProps {
  details: IDetails;
  selectNode: (name: string) => void;
  readonly: boolean;
}

const DetailsPanel = (props: IProps) => {
  const { details, selectNode, readonly } = props;
  const { type, name, action, routingComponent, array, instance } = details;
  const { getValues, setValue, trigger } = useFormContext<IRoutingTree>();
  const state = useStateContext();
  const [customerNode, setCustomerNode] = useState<ICustomerNode>();
  const { showSnackBar } = useSnackBar();
  const { loader } = useLoader();

  const variant = routingComponent?.ImplementationVariant.toLocaleLowerCase() ?? '';

  useEffect(() => {
    if (instance?.Node.CustomerNodeId) {
      loader(
        CustomerNodeService.get(instance.Node.CustomerNodeId)
          .then((customerNode) => {
            setCustomerNode(customerNode);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectRoutingComponent = (item: IRoutingComponent) => {
    if (type === DetailType.RootNode) {
      setValue('RootNode.RoutingComponentId', item.RoutingComponentId);
      setValue('RootNode.Service', item.Service);
      setValue('RootNode.ImplementationVariant', item.ImplementationVariant);
      array.remove();
    } else {
      if (!action) {
        throw new Error('action is mandatory for setting child node');
      }

      const instance: IRoutingTreeAction = {
        ActionId: action.ActionId,
        Service: action.Service,
        Node: {
          RoutingComponentId: item.RoutingComponentId,
          Service: item.Service,
          ImplementationVariant: item.ImplementationVariant
        }
      };

      if (item.Actions) {
        instance.Node.Actions = [];
      } else {
        instance.Node.CustomerNodeId = '';
      }

      if (type === DetailType.NotSet) {
        const actionsName = getActionsName(name);
        // @ts-ignore
        const actions = getValues(actionsName) as IRoutingTreeAction[];
        const activeNode = getIndexBasedName(name, actions.length);
        selectNode(activeNode);
        array.append(instance);
      } else {
        const index = getInstanceIndex(name);

        if (index === undefined) {
          throw new Error(`can not determine index for '${name}'`);
        }

        array.update(index, instance);
      }
    }
  };

  const deleteRoutingComponent = () => {
    if (type === DetailType.RootNode) {
      setValue('RootNode.RoutingComponentId', '');
      setValue('RootNode.Service', '');
      setValue('RootNode.ImplementationVariant', '');
      array.remove();
    } else {
      const index = getInstanceIndex(name);
      const activeNode = getServiceBasedName(name, action?.Service ?? '');
      selectNode(activeNode);
      array.remove(index);
    }
  };

  const selectCustomerNode = (item: ICustomerNode) => {
    const path = `${name}.Node.CustomerNodeId`;
    // @ts-ignore
    setValue(path, item.NodeId);
    setCustomerNode(item);
    trigger();
  };

  const deleteCustomerNode = () => {
    const path = `${name}.Node.CustomerNodeId`;
    // @ts-ignore
    setValue(path, '');
    setCustomerNode(undefined);
    trigger();
  };

  const getRoutingComponentType = () => {
    if (!routingComponent) {
      return ' ';
    }
    switch (type) {
      case DetailType.Adapter:
        return 'Adapter';
      case DetailType.Implementation:
      case DetailType.RootNode:
        return 'Implementation';
      default:
        return ' ';
    }
  };

  return (
    <Stack spacing={2} sx={{ minWidth: 600 }}>
      {action && (
        <Stack>
          <Header text='Action' />
          <Text label='Service' value={action.Service} />
          <Text label='Description' value={action.Description} />
        </Stack>
      )}

      <Stack>
        <Header text='Routing Component' />
        <Stack spacing={2} direction={'row'}>
          <Box>
            <Text label='Type' value={getRoutingComponentType()} />
            <Text label='Service' value={routingComponent?.Service} />
            <Text label='ImplementationVariant' value={routingComponent?.ImplementationVariant} />
            <Text label='Description' value={routingComponent?.Description} />
          </Box>
          <Stack spacing={1} paddingTop={1}>
            {state.selectRoutingComponent && (
              <SelectRoutingComponentDialog
                data={state.routingComponents}
                onClose={() => {
                  state.setSelectRoutingComponent(false);
                }}
                onSelect={selectRoutingComponent}
              />
            )}
            {!readonly && (
              <Button
                variant='contained'
                color='secondary'
                size='large'
                onClick={() => {
                  state.setSelectRoutingComponent(true);
                }}>
                Select
              </Button>
            )}

            {type !== DetailType.RootNode && !readonly && (
              <Button
                variant='contained'
                color='secondary'
                size='large'
                onClick={deleteRoutingComponent}
                disabled={!instance}>
                Delete
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      {type === DetailType.Adapter && (
        <Stack>
          <Header text='Customer Node' />
          <Stack spacing={2} direction={'row'}>
            <Box>
              <Text label='Description' value={customerNode?.Description} />
              <Text label='DTAP' value={getDtapDisplayValue(customerNode?.DTAPClass)} />
              <Text label='URI' value={customerNode?.URI} />
            </Box>
            <Stack spacing={1} paddingTop={1}>
              {state.selectCustomerNode && (
                <CustomerNodeSelectDialog
                  data={state.customerNodeMap.get(variant) ?? []}
                  onClose={() => {
                    state.setSelectCustomerNode(false);
                  }}
                  onSelect={selectCustomerNode}
                />
              )}
              {!readonly && (
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  disabled={!variant}
                  onClick={() => {
                    state.setSelectCustomerNode(true);
                  }}>
                  Select
                </Button>
              )}
              {!readonly && (
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  onClick={deleteCustomerNode}
                  disabled={!customerNode}>
                  Delete
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DetailsPanel;
