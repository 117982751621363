import { useEffect, useState } from 'react';
import { DataGridPro, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';

import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';
import { RoutingComponentService } from 'entities/RoutingComponent/RoutingComponentService';
import { DataGridContainer, DataGridProSx } from 'components/EntityListView/EntityListViewStyles';
import { Typography } from '@mui/material';
import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import { useSnackBar, useLoader } from 'context';

//===============================================
// private variables
//===============================================

const actionsValueGetter = (params: GridValueGetterParams<IRoutingComponent>) => {
  const actions = params.row.Actions;
  return actions ? actions.length : 0;
};

const detailPanelContent = (params: GridRowParams<IRoutingComponent>) => {
  const actions = params.row.Actions;

  if (actions) {
    return (
      <DataGridPro
        columns={action_columns}
        rows={actions}
        disableColumnPinning
        pagination={false}
        getRowId={(row: IRoutingComponentAction) => row.ActionId}
        initialState={{
          columns: { columnVisibilityModel: { ActionId: false } },
          sorting: {
            sortModel: [{ field: 'Service', sort: 'asc' }]
          }
        }}
      />
    );
  }

  return (
    <Typography align='center' variant='button' display='block' gutterBottom>
      no actions available
    </Typography>
  );
};

const action_columns: GridColDef[] = [
  { field: 'ActionId', headerName: 'Action ID', type: 'string', flex: 2 },
  { field: 'Service', headerName: 'Service', type: 'string', flex: 1 },
  { field: 'Description', headerName: 'Description', type: 'string', flex: 3 }
];

const routing_component_columns: GridColDef[] = [
  { field: 'RoutingComponentId', headerName: 'Routing Component ID', type: 'string', flex: 2 },
  { field: 'Service', headerName: 'Service', type: 'string', flex: 2 },
  { field: 'ImplementationVariant', headerName: 'Variant', type: 'string', flex: 1 },
  {
    field: 'Actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    type: 'number',
    flex: 1,
    valueGetter: actionsValueGetter
  },
  { field: 'Description', headerName: 'Description', type: 'string', flex: 3 }
];

//===============================================
// Component render function
//===============================================

const RoutingComponentListView = () => {
  const [data, setData] = useState<Array<IRoutingComponent>>([]);
  const { showSnackBar } = useSnackBar();
  const { loader } = useLoader();

  useEffect(() => {
    loader(
      RoutingComponentService.getAll()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataGridContainer>
      <DataGridPro
        sx={DataGridProSx}
        columns={routing_component_columns}
        rows={data}
        getRowId={(row: IRoutingComponent) => row.RoutingComponentId}
        disableColumnPinning
        pagination
        initialState={{
          columns: { columnVisibilityModel: { RoutingComponentId: false } },
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [
              { field: 'Service', sort: 'asc' },
              { field: 'ImplementationVariant', sort: 'asc' }
            ]
          }
        }}
        pageSizeOptions={[
          10,
          20,
          40
        ]}
        getDetailPanelContent={detailPanelContent}
        getDetailPanelHeight={() => 'auto'}
      />
    </DataGridContainer>
  );
};

export default RoutingComponentListView;
