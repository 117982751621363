import { useState } from 'react';
import Button from '@mui/material/Button';
import ContractImportDialog from './ContractImportDialog';

interface IProps {
  onClose: () => void;
}

const ShowDialogButton = (props: IProps) => {
  const { onClose: onCloseHandler } = props;
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    onCloseHandler();
  };

  return (
    <>
      <Button variant='contained' onClick={onOpen}>
        Import
      </Button>
      {open && <ContractImportDialog onClose={onClose} />}
    </>
  );
};

export default ShowDialogButton;
