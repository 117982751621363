import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

import config from 'config/config';
import { useKeycloak } from 'context/Keycloak';
import { LoaderProvider, SnackBarProvider, DialogProvider } from 'context';
import Layout from 'components/Layout/Layout';
import getDefaultTheme from 'styles/theme';
import RoutingComponentListView from 'pages/RoutingComponent/RoutingComponentListView';
import Application from 'routes/Application/Application';
import CustomerNode from 'routes/CustomerNode/CustomerNode';
import SubContract from 'routes/SubContract/SubContract';
import LoginPage from 'pages/Login/Login';
import DashBoard from 'pages/DashBoard/DashBoard';
import DSDesigner from 'pages/DashBoard/DSDesigner';
import Contract from 'routes/Contract/Contract';
import ServicePackage from 'routes/ServicePackage/ServicePackage';

function App() {
  const { initialized, authenticated } = useKeycloak();
  const theme = getDefaultTheme();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Router basename={config.basename}>
          {initialized() && !authenticated() && <LoginPage />}
          {authenticated() && (
            <LoaderProvider>
              <DialogProvider>
                <SnackBarProvider>
                  <Layout>
                    <Routes>
                      <Route index element={<DashBoard />} />
                      <Route path='DashBoard/DSDesigner' element={<DSDesigner />} />
                      <Route path='applications/*' element={<Application />} />
                      <Route path='contracts/*' element={<Contract />} />
                      <Route path='customer-nodes/*' element={<CustomerNode />} />
                      <Route path='routing-components' element={<RoutingComponentListView />} />
                      <Route path='service-packages/*' element={<ServicePackage />} />
                      <Route path='subcontracts/*' element={<SubContract />} />
                    </Routes>
                  </Layout>
                </SnackBarProvider>
              </DialogProvider>
            </LoaderProvider>
          )}
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
