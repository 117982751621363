import { Fragment, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { ApplicationService } from 'entities/Application/ApplicationService';
import { createEmptyApplication } from 'entities/Application/ApplicationUtils';
import ApplicationForm from 'pages/Application/ApplicationForm';
import IApplication from 'entities/Application/IApplication';

const ApplicationEdit = () => {
  const { applicationId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [application, setApplication] = useState<IApplication>(createEmptyApplication());
  const fetchedData = !!application.ApplicationId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    ApplicationService.get(applicationId)
      .then((application) => {
        setApplication(application);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  return fetchedData ? <ApplicationForm application={application} create={false} readonly={readonly} /> : <Fragment />;
};

export default ApplicationEdit;
