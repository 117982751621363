import Draggable from 'react-draggable';
import { Paper, PaperProps } from '@mui/material';

const DraggablePaper = (props: PaperProps) => {
  return (
    <Draggable handle={'[class*="MuiDialogTitle-root"]'} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export default DraggablePaper;
