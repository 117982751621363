import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema } from 'yup';
import ISubContract from './ISubContract';

export default class SubContractSchema extends EntitySchema<ISubContract> {
  override getSchema(): ISchema<ISubContract> {
    const schema: ObjectSchema<ISubContract> = object({
      SubContractId: this.identifier,
      ContractId: this.guid,
      Active: this.boolean,
      SubContractName: this.name,
      Description: this.description,
      ValidFrom: this.validFromDate.optional(),
      ValidTo: this.validToDate
    });

    return schema;
  }
}
