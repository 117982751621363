import { Fragment, useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { SubContractService } from 'entities/SubContract/SubContractService';
import { createEmptySubContract } from 'entities/SubContract/SubContractUtils';
import SubContractForm from 'pages/SubContract/SubContractForm';
import ISubContract from 'entities/SubContract/ISubContract';

const SubContractEdit = () => {
  const { subContractId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [subContract, setSubContract] = useState<ISubContract>(createEmptySubContract());
  const fetchedData = !!subContract.SubContractId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    SubContractService.get(subContractId)
      .then((subContract) => {
        setSubContract(subContract);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subContractId]);

  return fetchedData ? <SubContractForm subContract={subContract} readonly={readonly} /> : <Fragment />;
};

export default SubContractEdit;
