import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IServiceType from './IServiceType';

//==============================================================
// ServiceType EntityService
//==============================================================

class ServiceTypeService extends EntityService<IServiceType> {
  getAll(): Promise<IServiceType[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.ServiceTypes);
  }

  getId(item: IServiceType): string {
    return item.Code;
  }

  getPath(): string {
    return '/service-types';
  }

  //============================================================
  // unsupported methods
  //============================================================
  override delete(_id: string): Promise<IServiceType> {
    // delete not supported for ServiceType
    throw new Error('Method not implemented.');
  }

  override save(_item: IServiceType): Promise<IServiceType> {
    // save not supported for ServiceType
    throw new Error('Method not implemented.');
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new ServiceTypeService();

export { instance as ServiceTypeService };
