import { ChangeEvent, useState } from 'react';
import { Stack, TextField, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import DraggablePaper from 'components/DraggablePaper/DraggablePaper';
import { useSnackBar } from 'context';
import { ContractService, ImportMode } from 'entities/Contract/ContractService';

//===============================================
// Component props interface
//===============================================

interface IProps {
  onClose: () => void;
}

//===============================================
// Component render function
//===============================================

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

function ContractImportDialog(props: IProps) {
  const { onClose } = props;
  const [importMode, setImportMode] = useState<ImportMode>('skip');
  const [file, setFile] = useState<File | undefined>(undefined);
  const { showSnackBar } = useSnackBar();

  const onImportModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as ImportMode;
    setImportMode(value);
  };

  const onUpload = async () => {
    if (file) {
      const json = await file.text();
      let isValidJson = false;

      try {
        JSON.parse(json);
        isValidJson = true;
      } catch (error) {
        showSnackBar('Invalid json', 'error');
      }

      if (isValidJson) {
        ContractService.import(json, importMode)
          .then(() => {
            showSnackBar(`'${file.name}' imported successfully.`, 'success');
            onClose();
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          });
      }
    }
  };

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];

    if (file && file.type !== 'application/json') {
      showSnackBar('Only uploading json files is supported', 'error');
      setFile(undefined);
    } else {
      setFile(file);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={DraggablePaper} fullWidth maxWidth={'xs'}>
      <DialogTitle>
        <Box sx={{ fontWeight: 'bold', m: 1 }}>Import Contract</Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ innerHeight: 400 }}>
        <Stack sx={{ width: '100%' }}>
          <FormControl>
            <FormLabel>Mode</FormLabel>
            <RadioGroup value={importMode} onChange={onImportModeChange}>
              <FormControlLabel value='skip' control={<Radio />} label='skip' />
              <FormControlLabel value='merge' control={<Radio />} label='merge' />
              <FormControlLabel value='override' control={<Radio />} label='override' />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>File</FormLabel>
            <Stack direction={'row'} marginTop={1}>
              <TextField size='small' sx={{ width: 280 }} value={file?.name ?? ''} />
              <Button component='label' variant='contained' size='small' startIcon={<CloudUploadIcon />}>
                Select file
                <VisuallyHiddenInput type='file' accept='.json' onChange={onFileSelected} />
              </Button>
            </Stack>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onUpload} disabled={!file}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContractImportDialog;
