import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';

import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { SubContractInfo } from 'entities/SubContract/SubContractInfo';
import { ContractService } from 'entities/Contract/ContractService';
import { ApplicationInfo } from 'entities/Application/ApplicationInfo';
import { dateValueFormatter } from 'utils/DataGridUtils';
import { useSnackBar, useDialog, useLoader } from 'context';
import ShowDialogButton from 'entities_ui/Contract/ShowDialogButton';
import IContract from 'entities/Contract/IContract';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CreateButton from 'components/CreateButton/CreateButton';

//===============================================
// Component props interface
//===============================================

//===============================================
// Component render function
//===============================================

const ContractListView = () => {
  const [data, setData] = useState<Array<IContract>>([]);
  const navigate = useNavigate();
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listContracts = async () => {
    loader(
      ContractService.getAll()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    ContractService.delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully`, 'success');
        listContracts();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const dialogDelete = (props: GridRowParams<IContract>) => {
    openDialog(
      'Delete Contract',
      `You are about to remove ${props.row.ContractName}. This action is not reversible.`,
      () => {
        handleDelete(props.row.ContractId ?? '');
      }
    );
  };

  const exportContract = async (row: IContract) => {
    try {
      const contractJsonData = await ContractService.export(row.ContractId);

      const contractJsonStringifiedData = JSON.stringify(contractJsonData, null, 2);

      const blob = new Blob([contractJsonStringifiedData], { type: 'application/json' });
      const contractDownloadurl = URL.createObjectURL(blob);

      const contractAnchor = document.createElement('a');
      contractAnchor.href = contractDownloadurl;
      contractAnchor.download = row.ContractName + '.json';

      document.body.appendChild(contractAnchor);
      contractAnchor.click();
      URL.revokeObjectURL(contractDownloadurl);
    } catch (error: any) {
      showSnackBar(error.message, 'error');
    }
  };

  const getActions = (params: GridRowParams<IContract>) => [
    <GridActionsCellItem
      icon={<ApplicationInfo.Icon />}
      label={ApplicationInfo.displayName}
      onClick={() => navigate(`${params.row.ContractId}/applications`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />,
    <GridActionsCellItem
      icon={<SubContractInfo.Icon />}
      label={SubContractInfo.displayName}
      onClick={() => navigate(`${params.row.ContractId}/subcontracts`)}
      showInMenu
      disabled={!canPerformOperation('view')}
      divider
    />,
    <GridActionsCellItem
      icon={<FileDownloadIcon />}
      label={'Export'}
      onClick={() => exportContract(params.row)}
      showInMenu
      disabled={!canPerformOperation('delete')}
    />
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'ContractId', headerName: 'Contract ID', type: 'string', flex: 2 },
      { field: 'ContractName', headerName: 'Contract Name', type: 'string', flex: 2 },
      { field: 'Active', headerName: 'Active', type: 'boolean', flex: 1 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 3 },
      {
        field: 'ValidFrom',
        headerName: 'Valid From',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      },
      {
        field: 'ValidTo',
        headerName: 'Valid To',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      }
    ],
    []
  );
  useEffect(() => {
    listContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      columnVisibilityModel={{
        ContractId: false,
        Active: false
      }}
      getId={(row) => row.ContractId}
      getActions={getActions}
      deleteActionHandler={dialogDelete}
      buttons={[
        <CreateButton key='create' />,
        canPerformOperation('delete') ? (
          <ShowDialogButton key='import' onClose={listContracts} />
        ) : (
          <></>
        )

      ]}
    />
  );
};

export default ContractListView;
