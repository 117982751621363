import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import IRoutingTreeAction from 'entities/RoutingTree/IRoutingTreeAction';
import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';

//===============================================
// create functions
//===============================================

export const createActionsName = (nodeName: string) => {
  const nodePrefix = nodeName === 'RootNode' ? '' : '.Node';
  return `${nodeName}${nodePrefix}.Actions`;
};

export const createActionName = (
  actionsName: string,
  node: IRoutingTreeNode,
  action: IRoutingComponentAction,
  instance?: IRoutingTreeAction
) => {
  return instance ? `${actionsName}[${node.Actions?.indexOf(instance)}]` : `${actionsName}.${action.Service}`;
};

//===============================================
// get functions
//===============================================

export const getNodeName = (name: string) => {
  if (name === 'RootNode') {
    return 'RootNode';
  }

  const index = name.lastIndexOf('.Actions');
  return name.substring(0, index);
};

export const getActionsName = (name: string) => {
  if (name === 'RootNode') {
    return 'RootNode.Actions';
  }

  const index = name.lastIndexOf('.Actions');
  return name.substring(0, index + 8);
};

export const getInstanceIndex = (name: string) => {
  if (name.endsWith(']')) {
    const left = name.lastIndexOf('[');
    const right = name.lastIndexOf(']');
    const value = name.substring(left + 1, right);
    return Number(value);
  }

  return undefined;
};

export const getInstanceName = (name: string) => {
  if (name === 'RootNode') {
    return undefined;
  }

  return name;
};

export const getServiceName = (name: string) => {
  if (name.endsWith(']')) {
    return undefined;
  }

  const index = name.lastIndexOf('.Actions.');
  return name.substring(index + 9);
};

export const getIndexBasedName = (name: string, index: number) => {
  const last = name.lastIndexOf('.');
  return `${name.substring(0, last)}[${index}]`;
};

export const getServiceBasedName = (name: string, service: string) => {
  const last = name.lastIndexOf('[');
  return `${name.substring(0, last)}.${service}`;
};
