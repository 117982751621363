import { ReactNode, createContext, useContext, useEffect, useReducer } from 'react';
import { IState, createInitState, initializeStateAction, reducer, setStateAction } from './StateReducer';
import { useLoader } from 'context';

interface StateProviderProps {
  applicationId: string;
  children: ReactNode | undefined;
}

interface IStateContext extends IState {
  setActiveNode: (name: string) => void;
  setSelectRoutingComponent: (value: boolean) => void;
  setSelectCustomerNode: (value: boolean) => void;
}

const initState = createInitState();
const initStateContext = {
  ...initState,
  setActiveNode: () => {},
  setSelectRoutingComponent: () => {},
  setSelectCustomerNode: () => {}
};

const StateContext = createContext<IStateContext>(initStateContext);

const StateProvider = (props: StateProviderProps) => {
  const { applicationId, children } = props;
  const { loader } = useLoader();
  const [state, dispatch] = useReducer(reducer, initState);
  const value = {
    ...state,

    setActiveNode: (name: string) => {
      const payload = { activeNode: name };
      dispatch(setStateAction(payload));
    },

    setSelectRoutingComponent: (value: boolean) => {
      const payload = { selectRoutingComponent: value };
      dispatch(setStateAction(payload));
    },

    setSelectCustomerNode: (value: boolean) => {
      const payload = { selectCustomerNode: value };
      dispatch(setStateAction(payload));
    }
  };

  useEffect(() => {
    loader(initializeStateAction(applicationId).then((action) => dispatch(action)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

const useStateContext = () => {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useStateContext must be used within an StateProvider');
  }

  return context;
};

export { StateProvider, useStateContext };
