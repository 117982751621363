import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import ICacheConfiguration from './ICacheConfiguration';

//==============================================================
// CacheConfiguration EntityService
//==============================================================

export class CacheConfigurationService extends EntityService<ICacheConfiguration> {
  private subContractId: string;

  constructor(subContractId: string) {
    super();
    this.subContractId = subContractId;
  }

  getAll(): Promise<ICacheConfiguration[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.CacheConfigurations);
  }

  getId(item: ICacheConfiguration): string {
    return item.CacheConfigurationId || '';
  }

  getPath(): string {
    const subContractId = this.subContractId;
    return `/subcontracts/${subContractId}/cache-configurations`;
  }
}
