import { StyledLink, StyledListItemButton, StyledListItemIcon, StyledListItemText } from './SidebarNavLinkStyle';

type SidebarNavLinkParams = {
    path: string,
    name: string,
    open: boolean,
    icon: React.ReactNode
};


const SidebarNavLink = (props: SidebarNavLinkParams) => {
    return (
        <>
            <StyledLink to={props.path}>
                <StyledListItemButton sx={{ justifyContent: props.open ? 'initial' : 'center' }}>
                    <StyledListItemIcon sx={{ minWidth: 0, mr: props.open ? 3 : 'auto' }}>
                        {props.icon}
                    </StyledListItemIcon>
                    <StyledListItemText primary={props.name} sx={{ opacity: props.open ? 1 : 0 }} disableTypography />
                </StyledListItemButton>
            </StyledLink>
        </>
    )
};

export default SidebarNavLink;