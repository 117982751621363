import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import CacheConfigurationSchema from 'entities/CacheConfiguration/CacheConfigurationSchema';
import { Typography, Stack, Box } from '@mui/material';
import { CacheConfigurationService } from 'entities/CacheConfiguration/CacheConfigurationService';
import { useSnackBar } from 'context';
import ICacheConfiguration from 'entities/CacheConfiguration/ICacheConfiguration';
import NumberField from 'components/NumberField/NumberField';
import BooleanField from 'components/BooleanField/BooleanField';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

enum Operation {
  'create',
  'createFromDefault',
  'update'
}

const schema = new CacheConfigurationSchema().getSchema();

const getOperation = (cacheConfiguration: ICacheConfiguration) => {
  if (cacheConfiguration.CacheConfigurationId) {
    return Operation.update;
  }
  if (cacheConfiguration.CacheName) {
    return Operation.createFromDefault;
  }
  return Operation.create;
};

const getTitle = (operation: Operation, readonly: boolean) => {

  if (readonly) {
    return 'View Cache Configuration';
  }

  switch (operation) {
    case Operation.create:
      return 'Create Cache Configuration';
    case Operation.createFromDefault:
      return 'Create Cache Configuration from default';
    case Operation.update:
      return 'Update Cache Configuration';
  }
};

interface IProps {
  subContractId: string;
  cacheConfiguration: ICacheConfiguration;
  readonly?: boolean;
}

const CacheConfigurationForm = (props: IProps) => {
  const { subContractId, cacheConfiguration, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const { showSnackBar } = useSnackBar();
  const operation = getOperation(cacheConfiguration);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<ICacheConfiguration>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: cacheConfiguration
  });

  const onSubmit: SubmitHandler<ICacheConfiguration> = (cacheConfiguration) => {
    const action = operation === Operation.update ? 'updated' : 'created';
    const service = new CacheConfigurationService(subContractId);

    service
      .save(cacheConfiguration)
      .then((result) => {
        showSnackBar(`${result.CacheConfigurationId} ${action} successfully.`, 'success');
        navigateBack();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>{getTitle(operation,readonly)}</Typography>
        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <MuiTextField
            field='CacheName'
            control={control}
            label={'Name'}
            readonly={readonly}
            isRequired={true}
            disabled={operation === Operation.createFromDefault}
          />

          <BooleanField field='Enabled' control={control} readonly={readonly} />
          <NumberField field='MaxMemoryOffHeap' control={control} readonly={readonly} />
          <NumberField field='MaxElementsInMemory' control={control} readonly={readonly} />
          <NumberField field='MaxEntriesLocalDisk' control={control} readonly={readonly} />
          <NumberField field='TimeToIdle' control={control} readonly={readonly} />
          <NumberField field='TimeToLive' control={control} readonly={readonly} />

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default CacheConfigurationForm;
