function caseInsensitiveSort(a: string, b: string) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export function createDistinctSortedList<T>(list: T[], map: (item: T) => string): string[] {
  const items = new Map(
    list.map((item: T) => {
      const value = map(item);
      return [value.toLowerCase(), value];
    })
  );

  return [...items.values()].sort(caseInsensitiveSort);
}
