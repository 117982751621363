import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import IMUiDatePicker from './IMUiDatePicker';
import { isValid } from 'date-fns';
import { formatDateForApi } from 'utils';
import { Controller } from 'react-hook-form';

export const MuiDatePicker = (props: IMUiDatePicker) => {
  const { register } = props;
  const adapter = new AdapterDateFns();

  const dateChangeHandler = (changedDate: Date) => {
    let selectedDate = changedDate && isValid(changedDate) ? formatDateForApi(changedDate) : 'Invalid';

    if (!selectedDate.includes('Invalid')) {
      const selectedDateofDay = adapter.startOfDay(new Date(changedDate));
      const minDateStartOfDay = adapter.startOfDay(new Date(props.minDate));
      const maxDateEndOfDay = adapter.startOfDay(new Date(props.maxDate));

      if (selectedDateofDay > maxDateEndOfDay) {
        selectedDate = 'Invalid Date';
      }
      if (selectedDateofDay < minDateStartOfDay) {
        selectedDate = 'Invalid Date';
      }
    }

    props.onDateChange(selectedDate, props.name);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { value }, fieldState: { error } }) => {
          const date = adapter.startOfDay(new Date(value));

          return (
            <DatePicker
              views={[
                'year',
                'month',
                'day'
              ]}
              readOnly={props.disabled}
              format='dd/MM/yyyy'
              label={props.name}
              closeOnSelect
              minDate={new Date(props.minDate ?? null)}
              maxDate={new Date(props.maxDate ?? null)}
              value={isValid(date) ? date : null}
              {...register(props.name)}
              slotProps={{
                textField: {
                  id: props.name,
                  fullWidth: true,
                  variant: 'outlined',
                  error: !!error,
                  helperText: error?.message,
                  readOnly: props.disabled
                }
              }}
              onChange={dateChangeHandler}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default MuiDatePicker;
