import { useParams } from 'react-router-dom';
import { createEmptyServicePackage } from 'entities/ServicePackage/ServicePackageUtils';
import ServicePacakgeForm from 'pages/ServicePackage/ServicePackageForm';

const ServicePackageCreate = () => {
  const { subContractId = '' } = useParams();
  const servicePackage = createEmptyServicePackage();

  servicePackage.SubContractId = subContractId;

  return <ServicePacakgeForm servicePackage={servicePackage} />;
};

export default ServicePackageCreate;
