import TreeItem from '@mui/lab/TreeItem/TreeItem';
import Node from './Node';
import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import IRoutingTreeAction from 'entities/RoutingTree/IRoutingTreeAction';
import AdapterIcon from '@mui/icons-material/HdrAutoTwoTone';
import EmptyIcon from '@mui/icons-material/LensTwoTone';
import { useStateContext } from 'pages/RoutingTree/StateContext';

import { grey, red } from '@mui/material/colors';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';

//===============================================
// private variables
//===============================================

const getIcon = (instance: IRoutingTreeAction | undefined, routingComponents: IRoutingComponent[]) => {
  if (instance && instance.Node) {
    const routingComponent = routingComponents.find(
      (item) => item.RoutingComponentId === instance.Node.RoutingComponentId
    );

    if (routingComponent && !routingComponent.Actions) {
      const color = instance.Node.CustomerNodeId ? grey[500] : red[500];
      return <AdapterIcon sx={{ color }} />;
    }

    return null;
  }
  return <EmptyIcon sx={{ color: grey[500] }} />;
};

//===============================================
// Component props interface
//===============================================

interface IProps {
  action: IRoutingComponentAction;
  instance?: IRoutingTreeAction;
  name: string;
}

//===============================================
// Component render function
//===============================================

const Action = (props: IProps) => {
  const { action, instance, name } = props;
  const { routingComponents } = useStateContext();

  return (
    <TreeItem key={name} nodeId={name} label={action.Service} icon={getIcon(instance, routingComponents)}>
      {instance && <Node node={instance.Node} name={name} />}
    </TreeItem>
  );
};

export default Action;
