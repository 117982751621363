import { RoutingComponentService } from 'entities/RoutingComponent/RoutingComponentService';
import { MatchingTypeService } from 'entities/MatchingType/MatchingTypeService';
import { createVariantCustomerNodeMapping } from './DataQueries';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';
import IMatchingType from 'entities/MatchingType/IMatchingType';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';

type Payload = Partial<IState>;

export interface IState {
  activeNode: string;
  matchingTypes: IMatchingType[];
  routingComponents: IRoutingComponent[];
  customerNodeMap: Map<string, ICustomerNode[]>;
  selectRoutingComponent: boolean;
  selectCustomerNode: boolean;
}

export interface IAction {
  type: string;
  payload: Payload;
}

export const reducer = (state: IState, action: IAction) => {
  return { ...state, ...action.payload };
};

export const createInitState = (): IState => {
  return {
    activeNode: 'RootNode',
    matchingTypes: [],
    routingComponents: [],
    customerNodeMap: new Map<string, ICustomerNode[]>(),
    selectRoutingComponent: false,
    selectCustomerNode: false
  };
};

export async function initializeStateAction(applicationId: string) {
  const data = await Promise.all([
    MatchingTypeService.getAll(),
    RoutingComponentService.getAll(),
    createVariantCustomerNodeMapping(applicationId)]);

  return {
    type: 'initializeStateAction',
    payload: {
      matchingTypes: data[0],
      routingComponents: data[1],
      customerNodeMap: data[2]
    }
  };
}

export function setStateAction(payload: Payload) {
  return {
    type: 'setStateAction',
    payload: payload
  };
}
