import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema } from 'yup';
import IApplication from './IApplication';

// ApplicationId is generated by WebMethods and
// needs to be specified on creation by the user.

// TODO: improve ContractId / SubContractId validation
export default class ApplicationSchema extends EntitySchema<IApplication> {
  override getSchema(): ISchema<IApplication> {
    const schema: ObjectSchema<IApplication> = object({
      ApplicationId: this.guid,
      ContractId: this.guid.optional(),
      SubContractId: this.guid.optional(),
      ApplicationName: this.name,
      Description: this.description
    });

    return schema;
  }
}
