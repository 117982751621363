import { Box, Stack } from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import { StateProvider } from './StateContext';
import RoutingTreeSchema from 'entities/RoutingTree/RoutingTreeSchema';
import RoutingTreeDetails from './RoutingTreeDetails';
import RoutingTreeNodeEditor from './RoutingTreeNodeEditor/RoutingTreeNodeEditor';
import IRoutingTree from 'entities/RoutingTree/IRoutingTree';
import { RoutingTreeService } from 'entities/RoutingTree/RoutingTreeService';
import { useSnackBar } from 'context';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import useNavigateBack from 'hooks/useNavigateBack';

const schema = new RoutingTreeSchema().getSchema();

interface IProps {
  applicationId: string;
  routingTree: IRoutingTree;
  readonly?: boolean;
}

const RoutingTreeForm = (props: IProps) => {
  const { applicationId, routingTree, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const { showSnackBar } = useSnackBar();
  const update = !!routingTree.RoutingTreeId;

  const form = useForm<IRoutingTree>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: routingTree
  });

  const {
    formState: { isValid }
  } = form;

  const onSubmit: SubmitHandler<IRoutingTree> = (routingtree) => {
    const action = update ? 'updated' : 'created';
    const service = new RoutingTreeService(applicationId);

    service
      .save(routingtree)
      .then((data) => {
        showSnackBar(`${data.RoutingTreeId} ${action} successfully.`, 'success');
        navigateBack();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  // fetch the object from react-form-hook because
  // that contains array updates on re-render
  const rootNode = form.getValues('RootNode');

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <StateProvider applicationId={applicationId}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack width={1000}>
              <RoutingTreeDetails routingTree={routingTree} readonly={readonly} />

              {rootNode && <RoutingTreeNodeEditor rootNode={rootNode} readonly={readonly} />}

              <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
            </Stack>
          </form>
        </FormProvider>
      </StateProvider>
    </Box>
  );
};

export default RoutingTreeForm;
