import { createEmptyContract } from 'entities/Contract/ContractsUtils';
import ContractForm from 'pages/Contract/ContractForm';

const ContractCreate = () => {
  const contract = createEmptyContract();

  return <ContractForm contract={contract} />;
};

export default ContractCreate;
