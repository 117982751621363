import { format } from 'date-fns';

export const formatDateForApi = (date: Date): string => {
  if (!date) return '';
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  return formattedDate;
};

export const formatDateForUi = (date: Date | string): string => {
  return format(new Date(date ?? ''), 'dd-MM-yyyy');
};
