import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { RoutingTreeService } from 'entities/RoutingTree/RoutingTreeService';
import { createEmptyRoutingTree } from 'entities/RoutingTree/RoutingTreeUtils';
import RoutingTreeForm from 'pages/RoutingTree/RoutingTreeForm';
import IRoutingTree from 'entities/RoutingTree/IRoutingTree';

const RoutingTreeEdit = () => {
  const { applicationId = '', routingTreeId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [routingTree, setRoutingTree] = useState<IRoutingTree>(createEmptyRoutingTree());
  const fetchedData = !!routingTree.RoutingTreeId;
  const readonly = searchParams.get('readonly') === '' ?? false;

  useEffect(() => {
    const service = new RoutingTreeService(applicationId);

    service
      .get(routingTreeId)
      .then((routingTree) => {
        setRoutingTree(routingTree);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, routingTreeId]);

  return fetchedData ? (
    <RoutingTreeForm applicationId={applicationId} routingTree={routingTree} readonly={readonly} />
  ) : (
    <React.Fragment />
  );
};

export default RoutingTreeEdit;
