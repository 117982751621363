import { Route, Routes } from 'react-router-dom';
import ApplicationList from './ApplicationList';
import ApplicationCreate from './ApplicationCreate';
import ApplicationEdit from './ApplicationEdit';
import RoutingTree from 'routes/RoutingTree/RoutingTree';

const Application = () => {
  return (
    <Routes>
      <Route index element={<ApplicationList />} />
      <Route path='create' element={<ApplicationCreate />} />
      <Route path=':applicationId' element={<ApplicationEdit />} />
      <Route path=':applicationId/routing-trees/*' element={<RoutingTree />} />
    </Routes>
  );
};

export default Application;
