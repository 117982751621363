import { useParams } from 'react-router-dom';
import ApplicationListView from 'pages/Application/ApplicationListView';
import IApplication from 'entities/Application/IApplication';

const ApplicationList = () => {
  const { contractId = '', subContractId = '' } = useParams();

  const filter = () => {
    if (!!subContractId) {
      return (application: IApplication) => application.SubContractId === subContractId;
    }

    if (!!contractId) {
      return (application: IApplication) => application.ContractId === contractId;
    }

    return () => true;
  };

  return <ApplicationListView filter={filter()} />;
};

export default ApplicationList;
