import { formatDateForApi } from 'utils';

export function createEmptyRoutingTree() {
  return {
    RoutingTreeId: '',
    MatchingType: 'simple-matching',
    ValidFrom: formatDateForApi(new Date()),
    RootNode: {
      RoutingComponentId: '',
      Service: '',
      ImplementationVariant: '',
      Actions: []
    }
  };
}
