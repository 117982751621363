import { styled } from '@mui/material';

export const BlueScreen = styled('div')`
  background-color: ${(props) => props.theme.palette.primary.main};
  height: 100vh;
  width: 100vw;
`;

export const WhiteBox = styled('div')`
  background-color: white;
  height: 400px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CenteredColumn = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CCSLogo = styled('img')`
  padding-top: 50px;
  padding-bottom: 50px;
`;
