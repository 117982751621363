import { createDistinctSortedList } from 'utils/ArrayUtils';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';

type Payload = Partial<IState>;

//===============================================
// interfaces
//===============================================

export interface IState {
  service: string | null;
  variant: string | null;
  type: string | null;
  serviceOptions: string[];
  variantOptions: string[];
  typeOptions: string[];
}

export interface IAction {
  type: string;
  payload: Payload;
}

//===============================================
// actions
//===============================================

export function setOptionsAction(routingComponents: IRoutingComponent[]): IAction {
  const serviceOptions = createDistinctSortedList(routingComponents, (item) => item.Service);
  const variantOptions = createDistinctSortedList(routingComponents, (item) => item.ImplementationVariant);

  return {
    type: 'setOptions',
    payload: { serviceOptions, variantOptions }
  };
}

export function setStateAction(state: IState): IAction {
  return {
    type: 'setState',
    payload: state
  };
}

//===============================================
// reducer
//===============================================

export const reducer = (state: IState, action: IAction) => {
  return { ...state, ...action.payload };
};

//===============================================
// module export (misc)
//===============================================

export const createInitState = (): IState => {
  const typeOptions = ['Adapter', 'Implementation'];

  return {
    service: null,
    variant: null,
    type: null,
    serviceOptions: [],
    variantOptions: [],
    typeOptions
  };
};
