import { useStateContext } from '../../StateContext';
import Actions from './Actions';
import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';
import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import { createActionsName } from '../nameUtil';

const compareActions = (a: IRoutingComponentAction, b: IRoutingComponentAction) => {
  if (a.Service < b.Service) {
    return -1;
  }
  if (a.Service > b.Service) {
    return 1;
  }
  return 0;
};

interface IProps {
  node: IRoutingTreeNode;
  name: string;
}

const Node = (props: IProps) => {
  const { node, name } = props;
  const { routingComponents } = useStateContext();
  const routingComponent = routingComponents.find((item) => item.RoutingComponentId === node.RoutingComponentId);
  const actions = routingComponent?.Actions;
  const actionsName = createActionsName(name);

  if (actions) {
    actions.sort(compareActions);
  }

  return <>{actions && <Actions actions={actions} node={node} name={actionsName} />}</>;
};

export default Node;
