import { Route, Routes } from 'react-router-dom';
import DictionaryList from './DictionaryList';
import DictionaryEdit from './DictionaryEdit';

const Dictionary = () => {
  return (
    <Routes>
      <Route index element={<DictionaryList />} />
      <Route path=':dictionaryName' element={<DictionaryEdit />} />
    </Routes>
  );
};

export default Dictionary;
