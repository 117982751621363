import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { useStateContext } from '../StateContext';
import useDetails from './DetailsPanel/useDetails';
import TreeViewPanel from './TreeViewPanel/TreeViewPanel';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';

//===============================================
// private variables
//===============================================

const Panel = (props: { children: ReactNode }) => {
  return (
    <Box sx={{ border: 1, borderColor: 'rgba(0, 0, 0, 0.23)', borderRadius: 1, padding: 2 }}>{props.children}</Box>
  );
};

//===============================================
// Component props interface
//===============================================

interface IProps {
  rootNode: IRoutingTreeNode;
  readonly: boolean;
}

//===============================================
// Component render function
//===============================================

const RoutingTreeNodeEditor = (props: IProps) => {
  const { rootNode, readonly } = props;
  const { activeNode, setActiveNode } = useStateContext();
  const details = useDetails(activeNode);

  const selectNode = (name: string) => {
    setActiveNode(name);
  };

  return (
    <Stack spacing={1} direction={'row'} marginTop={2} marginBottom={2}>
      <Panel>
        <TreeViewPanel activeNode={activeNode} routingTreeNode={rootNode} selectNode={selectNode} />
      </Panel>
      <Panel>
        <DetailsPanel key={activeNode} details={details} selectNode={selectNode} readonly={readonly} />
      </Panel>
    </Stack>
  );
};

export default RoutingTreeNodeEditor;
