import { useParams } from 'react-router-dom';
import SubContractListView from 'pages/SubContract/SubContractListView';

const ContractList = () => {
  const { contractId = '' } = useParams();

  return <SubContractListView contractId={contractId} />;
};

export default ContractList;
