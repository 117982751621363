import { useParams } from 'react-router-dom';
import { createEmptyCustomerNode } from 'entities/CustomerNode/CustomerNodeUtils';
import CustomerNodeForm from 'pages/CustomerNode/CustomerNodeForm';

const CustomerNodeCreate = () => {
  const { servicePackageId = '' } = useParams();
  const customerNode = createEmptyCustomerNode();

  customerNode.ServicePackageId = servicePackageId;

  return <CustomerNodeForm customerNode={customerNode} />;
};

export default CustomerNodeCreate;
