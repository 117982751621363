import { ReactNode, createContext, useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface LoaderProviderProps {
  children: ReactNode | undefined;
}

interface ILoaderContext {
  loader<T>(promise: Promise<T>): Promise<T>;
}

export const LoaderContext = createContext<ILoaderContext>({
  loader: (promise) => promise
});

const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const loader = <T,>(promise: Promise<T>): Promise<T> =>
    Promise.resolve(true)
      .then(() => {
        setOpenBackdrop(true);
        return promise;
      })
      .finally(() => {
        setOpenBackdrop(false);
      });

  return (
    <LoaderContext.Provider value={{ loader }}>
      {children}
      {
        <Backdrop sx={{ color: '#525ce0', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
          <CircularProgress color='primary' />
        </Backdrop>
      }
    </LoaderContext.Provider>
  );
};

const useLoader = () => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader must be used within an LoaderProvider');
  }

  return context;
};

export { LoaderProvider, useLoader };
