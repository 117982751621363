import { AxiosError } from 'axios';
import RCIPError from './RCIPError';

interface IRCIPResponseError {
  message: string;
  type: string;
  code: string;
  transactionId: string;
}

const handleError = (error: any) => {
  if (error instanceof AxiosError && error.response?.data?.error) {
    const data = error.response.data.error as IRCIPResponseError;
    return new RCIPError(error, data.message, data.type, data.code, data.transactionId);
  }

  const message = error?.message ? error.message : 'Unknown error occured';
  return new RCIPError(error, message);
};

export { handleError };
