import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ContractInfo } from 'entities/Contract/ContractInfo';
import { SubContractInfo } from 'entities/SubContract/SubContractInfo';
import { ServicePackageInfo } from 'entities/ServicePackage/ServicePackageInfo';
import { CustomerNodeInfo } from 'entities/CustomerNode/CustomerNodeInfo';
import { RoutingComponentInfo } from 'entities/RoutingComponent/RoutingComponentInfo';

import SidebarNavLink from './SidebarNavLink';
import { AppBar, Drawer, DrawerHeader } from './SidebarStyle';
import { ApplicationInfo } from 'entities/Application/ApplicationInfo';
import AppBarMenu from 'components/AppBarMenu/AppBarMenu';

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{ marginRight: 5, ...(open && { display: 'none' }) }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            CCS Admin Portal Dashboard
          </Typography>
          <AppBarMenu></AppBarMenu>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <SidebarNavLink path='/contracts' name={ContractInfo.displayName} icon={<ContractInfo.Icon />} open={open} />
          <SidebarNavLink
            path='/subcontracts'
            name={SubContractInfo.displayName}
            icon={<SubContractInfo.Icon />}
            open={open}
          />
          <SidebarNavLink
            path='/applications'
            name={ApplicationInfo.displayName}
            icon={<ApplicationInfo.Icon />}
            open={open}
          />
          <SidebarNavLink
            path='/service-packages'
            name={ServicePackageInfo.displayName}
            icon={<ServicePackageInfo.Icon />}
            open={open}
          />
          <SidebarNavLink
            path='/customer-nodes'
            name={CustomerNodeInfo.displayName}
            icon={<CustomerNodeInfo.Icon />}
            open={open}
          />
          <SidebarNavLink
            path='/routing-components'
            name={RoutingComponentInfo.displayName}
            icon={<RoutingComponentInfo.Icon />}
            open={open}
          />
        </List>
      </Drawer>
    </>
  );
}
