import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useNavigateBack from 'hooks/useNavigateBack';

interface IProps {
  isSaveDisabled: boolean;
  readonly: boolean;
}

const OperationButtonGroup = (props: IProps) => {
  const { isSaveDisabled, readonly } = props;
  const navigateBack = useNavigateBack();

  return (
    <Stack spacing={2} direction={'row'}>
      <Button
        fullWidth
        data-testid={'cancel'}
        variant='contained'
        color='secondary'
        size='large'
        onClick={navigateBack}>
        {readonly ? 'Back' : 'CANCEL'}
      </Button>

      {!readonly && (
        <Button
          fullWidth
          id='submit'
          data-testid='save'
          variant='contained'
          color='primary'
          size='large'
          type='submit'
          disabled={isSaveDisabled}>
          Save
        </Button>
      )}
    </Stack>
  );
};

export default OperationButtonGroup;
