import { useParams } from 'react-router-dom';
import DictionaryListView from 'pages/Dictionary/DictionaryListView';

const DictionaryListAll = () => {
  const { customerNodeId = '' } = useParams();

  return <DictionaryListView customerNodeId={customerNodeId} />;
};

export default DictionaryListAll;
