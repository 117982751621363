import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IApplication from './IApplication';
import RCIPError from 'entities/RCIPError';

//==============================================================
// private functions
//==============================================================

// Application can only be bound to Contract or SubContract
// not to both.
const rewriteOutbound = (item: IApplication): IApplication => {
  const application = { ...item };
  const boundToSubContract = !!application.SubContractId;

  if (boundToSubContract) {
    delete application.ContractId;
  } else {
    delete application.SubContractId;
  }

  return application;
};

//==============================================================
// Application EntityService
//==============================================================

class ApplicationService extends EntityService<IApplication> {
  getAll(): Promise<IApplication[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.Applications);
  }

  getId(item: IApplication): string {
    return item.ApplicationId;
  }

  getPath(): string {
    return '/applications';
  }

  override save(item: IApplication, update: boolean = false): Promise<IApplication> {
    const id = this.getId(item);
    const path = this.getPath();
    const application = rewriteOutbound(item);
    return (update ? Server.put(`${path}/${id}`, application) : Server.post(path, application)).catch((error) => {
      throw this.errorHandler(error, id, item);
    });
  }

  override errorHandler(original: RCIPError, id: string, item: IApplication | null): RCIPError {
    const error = super.errorHandler(original, id, item);

    if (item && (error.code === '2207' || error.code === '2210')) {
      error.message = `${item.ApplicationName} already exists.`;
    }

    return error;
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new ApplicationService();

export { instance as ApplicationService };
