import { useState, useEffect, SyntheticEvent } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ICustomerNode from '../../entities/CustomerNode/ICustomerNode';
import IServicePackage from 'entities/ServicePackage/IServicePackage';
import { CustomerNodeService } from 'entities/CustomerNode/CustomerNodeService';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IProperties from 'entities/ServiceType/IServiceTypeProperty';
import IServiceType from 'entities/ServiceType/IServiceType';
import { ServiceTypeService } from 'entities/ServiceType/ServiceTypeService';
import { SubContractService } from 'entities/SubContract/SubContractService';
import ISubContract from 'entities/SubContract/ISubContract';

import MuiDatePicker from 'components/DateRangePicker/MuiDatePicker';
import MuiAutocomplete from 'components/Autocomplete/MuiAutocomplete';
import { useSnackBar, useLoader } from 'context';
import CustomerNodeSchema from 'entities/CustomerNode/CustomerNodeSchema';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

interface ICustomerNodeForm {
  customerNode: ICustomerNode;
  readonly?: boolean;
}

const schema = new CustomerNodeSchema().getSchema();

const CustomerNodeForm = (props: ICustomerNodeForm) => {
  const { customerNode, readonly = false } = props;
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [subContract, setSubContract] = useState<ISubContract>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [nowService, setNowService] = useState<IServiceType>();
  const [mandatoryProperties, setMandatoryProperties] = useState<Array<IProperties>>([]);
  const [associativeProperties, setAssociativeProperties] = useState<Array<IProperties>>([]);
  const [servicePackageOptions, setSericePacakgeOptions] = useState<Record<'value' | 'name', string>[]>([]);
  const { showSnackBar } = useSnackBar();
  const { loader } = useLoader();
  const navigateBack = useNavigateBack();
  const update = !!customerNode.NodeId;

  const onDateChangeHandler = (selectedDate: string, name: 'ValidTo' | 'ValidFrom') => {
    setValue(name, selectedDate, { shouldValidate: true });
  };
  const saveCustomerNode = (customerNode: ICustomerNode) => {
    const action = update ? 'updated' : 'created';

    loader(
      CustomerNodeService.save(customerNode)
        .then((data) => {
          navigateBack();
          showSnackBar(`${data.NodeId} ${action} successfully.`, 'success');
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  /**
   * 
   * @param customerNode IcustomerNode interface
   *Depending on the customer node's NodeId property, the method invokes either createNewNode or
   updateCustomerNode methods
   */
  const handleAsyncSubmit = (customerNode: ICustomerNode) => {
    const { ValidFrom, ValidTo } = customerNode;

    if (ValidFrom !== undefined && ValidFrom !== null && ValidTo !== undefined && ValidTo !== null) {
      const _prop = customerNode.Properties?.filter((property) => property.Value.length > 0) ?? [];
      const node = {
        ...customerNode,
        Properties: _prop,
        NodeId: props.customerNode.NodeId || '',
        ValidTo: ValidTo,
        ValidFrom: ValidFrom
      };
      saveCustomerNode(node);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    resetField,
    formState: { isValid }
  } = useForm<ICustomerNode>({
    resolver: yupResolver(schema as yup.AnyObjectSchema),
    mode: 'onChange',
    defaultValues: customerNode
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'Properties'
  });

  const handleSPChange = (_event: any, newValue: Record<'value' | 'name', string> | null) => {
    const selectedServicePkg = newValue ? newValue.value : '';
    setValue('ServicePackageId', selectedServicePkg ?? '', { shouldValidate: true });
    handleServicePackageChange(selectedServicePkg);
  };

  const handleServicePackageChange = async (servicePackageId: string) => {
    setShowTabs(false);
    if (servicePackageId.length) {
      loader(
        ServicePackageService.get(servicePackageId)
          .then((data) => {
            ServiceTypeService.get(data.ServiceTypeCode)
              .then((res) => {
                setNowService(res);
              })
              .then(() => {
                SubContractService.get(data.SubContractId).then((res) => {
                  setSubContract(res);
                });
              });
          })
          .catch((err) => {
            console.error(err);
          })
      );
    }
  };
  /**
   *
   * @param servicePacakges ServicePackages
   * @returns Options curated just for contracts | {value: ServicePakcageId, name: ServiceTypeCode}
   */
  const getServicePackageOptions = (servicePackages: IServicePackage[]) => {
    let options: Record<'value' | 'name', string>[] = servicePackages.map((servicePackage) => {
      return {
        value: servicePackage.ServicePackageId,
        name: servicePackage.ServiceTypeCode
      };
    });
    options = options.sort((a, b) => a.name.localeCompare(b.name));
    return setSericePacakgeOptions(options);
  };
  /**
   * dynamic form fields to go in 2 tabs
   */
  const RenderProperties = (): Record<'mandatoryFields' | 'assosiativeFields', any> => {
    const mandatoryFields: any = [];
    const assosiativeFields: any = [];
    if (fields && fields.length > 0) {
      fields.forEach((field, i: number) => {
        const property = mandatoryProperties?.filter((property) => property.Key === field.Key)[0];
        const assosiative = associativeProperties?.filter((property) => property.Key === field.Key)[0];
        if (property) {
          mandatoryFields.push(
            <TextField
              required
              key={field.Key}
              {...register(`Properties.${i}.Value`)}
              label={property.Key}
              name={`Properties[${i}].Value`}
              sx={{ marginTop: '1rem' }}
              InputProps={{ inputProps: { min: 0, readOnly: readonly ? true : false } }}
            />
          );
        } else if (assosiative) {
          assosiativeFields.push(
            <TextField
              {...register(`Properties.${i}.Value`)}
              label={field.Key}
              key={field.Key}
              sx={{ marginTop: '1rem' }}
              InputProps={{ inputProps: { min: 0, readOnly: readonly ? true : false } }}
            />
          );
        }
      });
      return { mandatoryFields, assosiativeFields };
    } else {
      return { mandatoryFields, assosiativeFields };
    }
  };

  /**
   * TAB change handler
   */
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setActiveTab(newValue);
  };
  /**
   * To hide/unhide the properties tab section, the hook will assert the current
   * service type properties length and handles the tab display state.
   */
  useEffect(() => {
    if (nowService?.Properties?.length) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [nowService?.Properties]);

  useEffect(() => {
    if (customerNode.ServicePackageId) {
      handleServicePackageChange(customerNode.ServicePackageId);
    }
    //eslint-disable-next-line
  }, [customerNode]);
  useEffect(() => {
    if (nowService && nowService.Properties?.length && nowService.Properties.length >= 1) {
      resetField('Properties');
      const mandatoryProperties = nowService.Properties.filter((prop) => !!prop.Mandatory);
      const associativeProperties = nowService.Properties.filter((prop) => !prop.Mandatory);
      setMandatoryProperties(mandatoryProperties);
      setAssociativeProperties(associativeProperties);
      const nodeProperties = nowService.Properties.map((prop) => {
        const { Description, Mandatory, Encrypted, ...rest } = prop;
        if (Mandatory) {
          return { Mandatory, Value: '', ...rest };
        } else {
          return { Value: '', ...rest };
        }
      });
      if (nodeProperties.length) {
        const customerNodePropertyKeys = customerNode.Properties?.map((p) => p.Key);
        append(nodeProperties.filter((prop) => !customerNodePropertyKeys?.includes(prop.Key)));
      }
    }
  }, [
    nowService,
    resetField,
    append,
    customerNode
  ]);

  useEffect(() => {
    loader(
      ServicePackageService.getAll()
        .then((data) => {
          if (data && Array.isArray(data)) {
            getServicePackageOptions(data);
          }
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
    //eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form
        data-testid='customer-node-form'
        id='customer-node-form'
        onSubmit={handleSubmit((values: ICustomerNode) => {
          handleAsyncSubmit(values);
        })}>
        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
            {update ? (readonly ? 'View' : 'Update') : 'Create'} Customer Node
          </Typography>

          <MuiAutocomplete
            register={register}
            name='ServicePackageId'
            control={control}
            id='ServicePackageId'
            options={servicePackageOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(servicePackage: any) => servicePackage.name + ' ' + servicePackage.value}
            value={
              servicePackageOptions.find((servicePackage) => servicePackage.value === getValues('ServicePackageId')) ||
              null
            }
            label='Service Package *'
            disabled={!!customerNode.ServicePackageId}
            onChange={handleSPChange}
          />

          <MuiTextField field='URI' control={control} readonly={readonly} />

          <MuiTextField field='Description' control={control} readonly={readonly} />

          <Controller
            name='DTAPClass'
            control={control}
            render={({ field: { onChange, onBlur, name, value }, fieldState: { invalid } }) => (
              <FormControl fullWidth id='form-control-dtap-class' data-testid='form-control-dtap-class'>
                <InputLabel required data-testid='form-control-label-dtap' id='form-control-label-dtap'>
                  DTAP Class
                </InputLabel>
                <Select
                  label='DTAP Class *'
                  id='form-control-select-dtap-class'
                  data-testid='form-control-select-dtap-class'
                  key='form-control-select-dtap-class'
                  value={value}
                  labelId='form-control-label-dtap'
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={invalid}
                  readOnly={readonly}>
                  <MenuItem value='dev' key='dev' id='menu-item-desc' data-testid='menu-item-desc'>
                    Development
                  </MenuItem>
                  <MenuItem value='test' key='test' data-testid='menu-item-test' id='menu-item-test'>
                    Test
                  </MenuItem>
                  <MenuItem value='acc' key='acc' data-testid='menu-item-acc' id='menu-item-acc'>
                    Acceptance
                  </MenuItem>
                  <MenuItem value='prod' key='prod' data-testid='menu-item-prod' id='menu-item-prod'>
                    Production
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Stack spacing={2} direction={'row'}>
            <MuiDatePicker
              name={'ValidFrom'}
              control={control}
              register={register}
              minDate={subContract?.ValidFrom ?? ''}
              maxDate={subContract?.ValidTo ?? ''}
              disabled={customerNode?.NodeId ? true : false}
              onDateChange={onDateChangeHandler}
            />

            <MuiDatePicker
              name={'ValidTo'}
              control={control}
              register={register}
              minDate={subContract?.ValidFrom ?? ''}
              maxDate={subContract?.ValidTo ?? ''}
              disabled={customerNode?.NodeId ? true : false}
              onDateChange={onDateChangeHandler}
            />
          </Stack>

          {showTabs && (
            <div id='customer-node-properties' data-testid='customer-node-properties'>
              <Typography fontSize='18px' fontWeight='700' id='properties-title' data-testid='properties-title'>
                Properties
              </Typography>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                id='properties-tab-section'
                data-testid='properties-tab-section'>
                <Tab label='mandatory' id='mandatory-properties-tab' data-testid='mandatory-properties-tab' />
                <Tab label='associative' id='associative-properties-tab' data-testid='associative-properties-tab' />
              </Tabs>
              {activeTab === 0 && mandatoryProperties && mandatoryProperties.length > 0 && (
                <Stack>
                  {RenderProperties().mandatoryFields.length &&
                    RenderProperties().mandatoryFields.map((field: any) => field)}
                </Stack>
              )}
              {activeTab === 1 && associativeProperties && associativeProperties.length > 0 && (
                <Stack>
                  {RenderProperties().assosiativeFields.length &&
                    RenderProperties().assosiativeFields.map((field: any) => field)}
                </Stack>
              )}
            </div>
          )}

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default CustomerNodeForm;
