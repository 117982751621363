import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateButton = () => {
  const navigate = useNavigate();

  const onCreateHandler = () => {
    navigate('create');
  };

  return (
    <Button variant='contained' onClick={onCreateHandler}>
      Create
    </Button>
  );
};

export default CreateButton;
