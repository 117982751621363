import { KeyboardEvent, ReactNode, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { DataGridProSx } from 'components/EntityListView/EntityListViewStyles';
import DraggablePaper from 'components/DraggablePaper/DraggablePaper';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

//===============================================
// Component props interface
//===============================================

interface IProps<T> {
  title: string;
  columns: GridColDef[];
  data: T[];
  children: ReactNode;
  filterModel: GridFilterModel;
  initialState?: GridInitialStatePro;
  getItemId: (item: T) => string;
  onClose: () => void;
  onSelect: (item: T) => void;
}

//===============================================
// Component render function
//===============================================

function EntitySelectDialog<T>(props: IProps<T>) {
  const { title, columns, data, children, filterModel, initialState, getItemId, onClose, onSelect } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const apiRef = useGridApiRef();

  const select = () => {
    if (rowSelectionModel[0]) {
      const id = rowSelectionModel[0].toString();
      const item = data.find((item) => getItemId(item) === id) as T;
      onSelect(item);
      onClose();
    }
  };

  const onRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  };

  const onStateChange = () => {
    const filteredRows = gridFilteredSortedRowIdsSelector(apiRef);
    const selectedRow = rowSelectionModel[0];

    // auto clear selection
    if (selectedRow && !filteredRows.find((row) => row === selectedRow)) {
      setRowSelectionModel([]);
    }

    // auto set selection
    if (filteredRows.length === 1 && selectedRow !== filteredRows[0]) {
      setRowSelectionModel([...filteredRows]);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.code === 'Enter' && rowSelectionModel.length) {
      e.preventDefault();
      select();
    } else if (e.code === 'Escape') {
      onClose();
    }
  };

  return (
    <Dialog open={true} onClose={onClose} PaperComponent={DraggablePaper} fullWidth maxWidth={'md'}>
      <DialogTitle>
        <Box sx={{ fontWeight: 'bold', m: 1 }}>{title}</Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ innerHeight: 400 }} onKeyDown={onKeyDown}>
        <Container maxWidth='sm'>{children}</Container>
        <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
          <DataGridPro
            apiRef={apiRef}
            sx={DataGridProSx}
            columns={columns}
            rows={data}
            getRowId={(row) => getItemId(row)}
            disableColumnFilter
            disableColumnPinning
            disableMultipleRowSelection
            onRowDoubleClick={select}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            onStateChange={onStateChange}
            filterModel={filterModel}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
              ...initialState
            }}
            pagination
            pageSizeOptions={[
              5,
              10,
              20
            ]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={select} disabled={!rowSelectionModel.length}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntitySelectDialog;
