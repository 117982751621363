/* eslint-disable no-template-curly-in-string */

import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema, string } from 'yup';
import IServicePackage from './IServicePackage';

export default class ServicePackageSchema extends EntitySchema<IServicePackage> {
  private serviceTypeCode = string().defined().min(1, '${path} must be at least 1 character').max(25);

  override getSchema(): ISchema<IServicePackage> {
    const schema: ObjectSchema<IServicePackage> = object({
      ServicePackageId: this.identifier,
      SubContractId: this.guid,
      Description: this.description,
      ServiceTypeCode: this.serviceTypeCode
    });

    return schema;
  }
}
