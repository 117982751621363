import { Route, Routes } from 'react-router-dom';
import RoutingTreeList from './RoutingTreeList';
import RoutingTreeCreate from './RoutingTreeCreate';
import RoutingTreeEdit from './RoutingTreeEdit';

const RoutingTree = () => {
  return (
    <Routes>
      <Route index element={<RoutingTreeList />} />
      <Route path='create' element={<RoutingTreeCreate />} />
      <Route path=':routingTreeId' element={<RoutingTreeEdit />} />
    </Routes>
  );
};

export default RoutingTree;
