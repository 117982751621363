import { useParams } from 'react-router-dom';
import { createEmptyCacheConfiguration } from 'entities/CacheConfiguration/CacheConfigurationUtils';
import CacheConfigurationForm from 'pages/CacheConfiguration/CacheConfigurationForm';

const CacheConfigurationCreate = () => {
  const { subContractId = '' } = useParams();
  const emptyCacheConfiguration = createEmptyCacheConfiguration();

  return <CacheConfigurationForm subContractId={subContractId} cacheConfiguration={emptyCacheConfiguration} />;
};

export default CacheConfigurationCreate;
