import { useEffect, useState, useMemo } from 'react';
import { GridActionsCellItem, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { useSnackBar, useDialog, useLoader } from 'context';
import ICacheConfiguration from 'entities/CacheConfiguration/ICacheConfiguration';
import { CacheConfigurationService } from 'entities/CacheConfiguration/CacheConfigurationService';

const typeValueGetter = (params: GridValueGetterParams<ICacheConfiguration>) => {
  const type = params.row.CacheConfigurationId ? 'Custom' : 'Default';
  return type;
};

//===============================================
// Component props interface
//===============================================

interface ICacheConfigurationListViewListViewProps {
  subContractId: string;
}

//===============================================
// Component render function
//===============================================

const CacheConfigurationListView = (props: ICacheConfigurationListViewListViewProps) => {
  const { subContractId } = props;
  const [data, setData] = useState<Array<ICacheConfiguration>>([]);
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listCacheConfigurations = async () => {
    const service = new CacheConfigurationService(subContractId);

    loader(
      service
        .getAll()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    const service = new CacheConfigurationService(subContractId);

    service
      .delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
      .finally(() => {
        listCacheConfigurations();
      });
  };

  const dialogDelete = (props: GridRowParams<ICacheConfiguration>) => {
    openDialog(
      'Delete Cache Configuration',
      `You are about to remove ${props.row.CacheConfigurationId}. This action is not reversible.`,
      () => {
        handleDelete(props.row.CacheConfigurationId ?? '');
      }
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'CacheConfigurationId', headerName: 'Cache Configuration ID', type: 'string', flex: 2 },
      { field: 'CacheName', headerName: 'Cache Configuration Name', type: 'string', flex: 2 },
      { field: 'Type', headerName: 'Type', type: 'string', flex: 1, valueGetter: typeValueGetter },
      { field: 'Enabled', headerName: 'Enabled', type: 'boolean', flex: 1 }
    ],
    []
  );

  const getActions = (params: GridRowParams<ICacheConfiguration>) => [
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label='Delete'
      onClick={() => dialogDelete(params)}
      showInMenu
      disabled={!params.row.CacheConfigurationId || !canPerformOperation('delete')}
    />
  ];

  useEffect(() => {
    listCacheConfigurations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      columnVisibilityModel={{
        CacheConfigurationId: false
      }}
      getId={(row) => row.CacheConfigurationId ?? row.CacheName}
      getActions={getActions}
    />
  );
};

export default CacheConfigurationListView;
