import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

import config from 'config/config';

//===============================================
// private variables
//===============================================

const keycloak = new Keycloak(config.keycloak);

const defaultOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  pkceMethod: 'S256'
};

//===============================================
// interfaces
//===============================================

interface IKeycloakContext {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  authenticated: () => boolean;
  initialized: () => boolean;
}

interface IKeycloakProviderProps {
  children: ReactNode | undefined;
}

//===============================================
// context
//===============================================

const KeycloakContext = createContext<IKeycloakContext>({
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  authenticated: () => false,
  initialized: () => false
});

//===============================================
// provider
//===============================================

const KeycloakProvider = (props: IKeycloakProviderProps) => {
  const { children } = props;
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const initialize = () => {
      keycloak
        .init({ ...defaultOptions })
        .then(() => {
          setInitialized(true);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    login: () => keycloak.login(),
    logout: () => keycloak.logout(),
    authenticated: () => keycloak.authenticated ?? false,
    initialized: () => initialized
  };

  return <KeycloakContext.Provider value={value}>{children}</KeycloakContext.Provider>;
};

//===============================================
// hook
//===============================================

const useKeycloak = () => {
  const context = useContext(KeycloakContext);

  if (!context) {
    throw new Error('useKeycloak must be used within an KeycloakProvider');
  }

  return context;
};

//===============================================
// public variables
//===============================================

const getToken = () => {
  return keycloak.token;
};

const getScope = (): string => {
  const tokenParsed = keycloak.tokenParsed;
  return tokenParsed ? tokenParsed['scope'] : '';
};

const updateToken = () => keycloak.updateToken(-1);

export { KeycloakProvider, useKeycloak, getToken, getScope, updateToken };
