import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useKeycloak } from 'context/Keycloak';
import { BlueScreen, WhiteBox, CenteredColumn, CCSLogo } from './LoginStyles';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useKeycloak();
  const logo = require('CCS_Logo.png');

  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlueScreen>
      <WhiteBox>
        <CenteredColumn>
          <CCSLogo src={logo} />
          <Button color='secondary' variant='contained' onClick={() => login()}>
            Login
          </Button>
        </CenteredColumn>
      </WhiteBox>
    </BlueScreen>
  );
};

export default Login;
