import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton, ListItemIcon } from '@mui/material';

export const StyledLink = styled(Link)({
  color: 'rgb(0, 0, 90)',
  textDecoration: 'none',
  margin: 1,
  position: 'relative',
  fontWeight: 600
});

export const StyledListItemButton = styled(ListItemButton)({
  minHeight: 48,
  px: 2.5
});

export const StyledListItemIcon = styled(ListItemIcon)({
  justifyContent: 'center',
  color: 'rgb(0, 0, 90)'
});

export const StyledListItemText = styled(ListItemText)({
  color: 'rgb(0, 0, 90)',
  fontWeight: '600 !important'
});
