import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContractService } from 'entities/Contract/ContractService';
import { SubContractService } from 'entities/SubContract/SubContractService';
import IApplication from 'entities/Application/IApplication';
import ApplicationSchema from 'entities/Application/ApplicationSchema';
import { ApplicationService } from 'entities/Application/ApplicationService';
import {
  Typography,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SelectChangeEvent,
  Box
} from '@mui/material';
import MuiAutocomplete from 'components/Autocomplete/MuiAutocomplete';
import { useSnackBar } from 'context';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

const schema = new ApplicationSchema().getSchema();

interface IApplicationForm {
  create: boolean;
  application: IApplication;
  readonly?: boolean;
}
interface Option {
  name: string;
  value: string;
}

enum Modus {
  contract,
  subcontract,
  not_set
}

const getModus = (application: IApplication): Modus => {
  if (!!application.SubContractId) {
    return Modus.subcontract;
  }

  if (!!application.ContractId) {
    return Modus.contract;
  }

  return Modus.not_set;
};

const ApplicationForm = (props: IApplicationForm) => {
  const { create, application, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const modus = getModus(application);
  const [type, setType] = useState('contract');
  const [options, setOptions] = useState<Option[]>([]);
  const isContract = modus === Modus.contract || (modus === Modus.not_set && type === 'contract');
  const isSubContract = modus === Modus.subcontract || (modus === Modus.not_set && type === 'subcontract');
  const { showSnackBar } = useSnackBar();
  const update = !!application.ApplicationId;

  const {
    register,
    handleSubmit,
    unregister,
    setValue,
    getValues,
    control,
    formState: { isValid }
  } = useForm<IApplication>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: application
  });

  const onSubmit: SubmitHandler<IApplication> = (application) => {
    const action = update ? 'updated' : 'created';

    if (!application.Description) {
      delete application.Description;
    }

    ApplicationService.save(application, !create)
      .then((data) => {
        showSnackBar(`${data.ApplicationId} ${action} successfully.`, 'success');
        navigateBack();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const onChangeType = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setOptions([]);
    setType(value);
  };

  useEffect(() => {
    if (isContract) {
      ContractService.getAll()
        .then((contracts) => {
          if (Array.isArray(contracts)) {
            const options: Option[] = contracts.map((contract) => ({
              name: contract.ContractName,
              value: contract.ContractId
            }));

            setOptions(options);

            if (modus === Modus.not_set) {
              setValue('ContractId', '');
              unregister('SubContractId');
            }
          }
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }

    if (isSubContract) {
      SubContractService.getAll()
        .then((subcontracts) => {
          if (Array.isArray(subcontracts)) {
            const options: Option[] = subcontracts.map((subcontract) => ({
              name: subcontract.SubContractName,
              value: subcontract.SubContractId
            }));

            setOptions(options);

            if (modus === Modus.not_set) {
              setValue('SubContractId', '');
              unregister('ContractId');
            }
          }
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const contractChangeHandler = (_event: any, newValue: Option | null) => {
    const selectedContractId = newValue ? newValue.value : '';
    setValue('ContractId', selectedContractId ?? '', { shouldValidate: true });
    unregister('SubContractId');
  };

  const subContractChangeHandler = (_event: any, newValue: Option | null) => {
    const selectedSubContractId = newValue ? newValue.value : '';
    setValue('SubContractId', selectedSubContractId ?? '', { shouldValidate: true });
    unregister('ContractId');
  };

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
            {update ? (readonly ? 'View' : 'Update') : 'Create'} Application
          </Typography>
          {modus === Modus.not_set && (
            <FormControl>
              <FormLabel>Type</FormLabel>
              <RadioGroup onChange={onChangeType} value={type} row>
                <FormControlLabel value='contract' control={<Radio />} label='Contract' />
                <FormControlLabel value='subcontract' control={<Radio />} label='Sub Contract' />
              </RadioGroup>
            </FormControl>
          )}

          {isContract && (
            <MuiAutocomplete
              register={register}
              name='ContractId'
              control={control}
              id='ContractId'
              options={options.sort((a, b) => a.name.localeCompare(b.name))}
              getOptionLabel={(option: any) => option.name}
              value={options.find((option: any) => option.value === getValues('ContractId')) || null}
              disabled={modus !== Modus.not_set}
              label='Contract *'
              onChange={contractChangeHandler}
            />
          )}

          {isSubContract && (
            <MuiAutocomplete
              register={register}
              name='SubContractId'
              control={control}
              id='SubContractId'
              options={options.sort((a, b) => a.name.localeCompare(b.name))}
              getOptionLabel={(option: any) => option.name}
              value={options.find((option) => option.value === getValues('SubContractId')) || null}
              disabled={modus !== Modus.not_set}
              label='Sub Contract *'
              onChange={subContractChangeHandler}
            />
          )}

          <MuiTextField
            field='ApplicationId'
            control={control}
            label={'Application ID'}
            readonly={readonly}
            isRequired={true}
          />

          <MuiTextField
            field='ApplicationName'
            control={control}
            label={'Name'}
            readonly={readonly}
            isRequired={true}
          />

          <MuiTextField field='Description' control={control} readonly={readonly} />

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default ApplicationForm;
