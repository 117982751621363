import Action from './Action';
import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';
import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import { createActionName } from '../nameUtil';

interface IProps {
  actions: IRoutingComponentAction[];
  node: IRoutingTreeNode;
  name: string;
}

const Actions = (props: IProps) => {
  const { actions, node, name } = props;

  return (
    <>
      {actions.map((action, index) => {
        const instance = node.Actions?.find((item) => item.ActionId === action.ActionId);
        const actionName = createActionName(name, node, action, instance);

        return <Action key={index} action={action} instance={instance} name={actionName} />;
      })}
    </>
  );
};

export default Actions;
