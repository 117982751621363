import { useParams } from 'react-router-dom';
import CustomerNodeListView from 'pages/CustomerNode/CustomerNodeListView';

const CustomerNodeList = () => {
  const { servicePackageId = '' } = useParams();

  return <CustomerNodeListView servicePackageId={servicePackageId} />;
};

export default CustomerNodeList;
