import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IMatchingType from './IMatchingType';

//==============================================================
// MatchingType EntityService
//==============================================================

class MatchingTypeService extends EntityService<IMatchingType> {
  getAll(): Promise<IMatchingType[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.MatchingTypes);
  }

  getId(item: IMatchingType): string {
    return item.Code;
  }

  getPath(): string {
    return '/matching-types';
  }

  //============================================================
  // unsupported methods
  //============================================================
  override delete(_id: string): Promise<IMatchingType> {
    // delete not supported for MatchingType
    throw new Error('Method not implemented.');
  }

  override get(_id: string): Promise<IMatchingType> {
    // get by id not supported for MatchingType
    throw new Error('Method not implemented.');
  }

  override save(_item: IMatchingType): Promise<IMatchingType> {
    // save not supported for MatchingType
    throw new Error('Method not implemented.');
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new MatchingTypeService();

export { instance as MatchingTypeService };
