import { ApplicationService } from 'entities/Application/ApplicationService';
import { SubContractService } from 'entities/SubContract/SubContractService';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';
import { CustomerNodeService } from 'entities/CustomerNode/CustomerNodeService';

import IApplication from 'entities/Application/IApplication';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';

async function getSubContractIds(application: IApplication) {
  if (application.ContractId) {
    const subContracts = await SubContractService.getByContractId(application.ContractId);
    return subContracts.map((i) => i.SubContractId);
  }

  return application.SubContractId ? [application.SubContractId] : [];
}

export async function createVariantCustomerNodeMapping(applicationId: string) {
  // fetch data in parallel
  const data = await Promise.all([
    CustomerNodeService.getAll(),
    ServicePackageService.getAll(),
    ApplicationService.get(applicationId).then((application) => getSubContractIds(application))
  ]);

  // assing data to variables
  const allCustomerNodes = data[0];
  const allServicePackages = data[1];
  const subContractIdSet = new Set(data[2]);
  const map = new Map<string, ICustomerNode[]>();

  // get service-packages for application
  const servicePackages = allServicePackages.filter((servicePackage) =>
    subContractIdSet.has(servicePackage.SubContractId)
  );

  // map customer-nodes by service-type
  servicePackages.forEach((servicePackage) => {
    const serviceType = servicePackage.ServiceTypeCode.toLowerCase();
    const customerNodes = allCustomerNodes.filter((i) => i.ServicePackageId === servicePackage.ServicePackageId);
    const customerNodeArray = map.get(serviceType) ?? [];
    const customerNodeSet = new Set([...customerNodeArray, ...customerNodes]);
    map.set(serviceType, [...customerNodeSet]);
  });

  return map;
}
