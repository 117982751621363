import { Route, Routes } from 'react-router-dom';
import CacheConfigurationList from './CacheConfigurationList';
import CacheConfigurationCreate from './CacheConfigurationCreate';
import CacheConfigurationEditOrCreate from './CacheConfigurationEditOrCreate';

const CacheConfiguration = () => {
  return (
    <Routes>
      <Route index element={<CacheConfigurationList />} />
      <Route path='create' element={<CacheConfigurationCreate />} />
      <Route path=':cacheConfigurationId' element={<CacheConfigurationEditOrCreate />} />
    </Routes>
  );
};

export default CacheConfiguration;
