import { useParams } from 'react-router-dom';
import CacheConfigurationListView from 'pages/CacheConfiguration/CacheConfigurationListView';

const CacheConfigurationListAll = () => {
  const { subContractId = '' } = useParams();

  return <CacheConfigurationListView subContractId={subContractId} />;
};

export default CacheConfigurationListAll;
