import { createTheme, Theme } from '@mui/material/styles';

const default_theme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          height: '100vh',
          width: '100vw',
          boxSizing: 'border-box'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Open Sans'
  },
  palette: {
    primary: {
      main: '#00005a'
    },
    secondary: {
      main: '#0046ff'
    }
  }
};

const getDefaultTheme = (): Theme => {
  const theme = createTheme(default_theme);
  return theme;
};

export default getDefaultTheme;
