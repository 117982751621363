import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import { SubContractService } from 'entities/SubContract/SubContractService';
import IServicePackage from './IServicePackage';

//==============================================================
// private variables
//==============================================================

const get = (path: string) => Server.get(path).then((data) => data?.ServicePackages);

//==============================================================
// ServicePackage EntityService
//==============================================================

class ServicePackageService extends EntityService<IServicePackage> {
  getAll(): Promise<IServicePackage[]> {
    return get(this.getPath());
  }

  getBySubContractId(id: string): Promise<IServicePackage[]> {
    return get(`${SubContractService.getPath()}/${id}/${this.getPath()}`);
  }

  getId(item: IServicePackage): string {
    return item.ServicePackageId;
  }

  getPath(): string {
    return '/service-packages';
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new ServicePackageService();

export { instance as ServicePackageService };
