import genRandomId from './random';

/**
 *
 * @param value string The value which should be used as option value
 * @param list Array<Record<string, string>> List of records from which options should be generated
 * @param lable string The label of option, If not provided then value will be used as lable
 * @returns Record<id|value|lable, string>
 */
const generateOptions = (
  value: string,
  list: Array<Record<string, any>>,
  lable?: string
): Record<'lable' | 'value' | 'id', string>[] => {
  const _lable = lable ?? value;
  const options = list?.map((record, id) => {
    return {
      value: record[value],
      lable: record[_lable],
      id: `option-${id}-${genRandomId()}`
    };
  });
  return options;
};

export default generateOptions;
