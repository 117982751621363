import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IRoutingTree from './IRoutingTree';

//==============================================================
// RoutingTree EntityService
//==============================================================

export class RoutingTreeService extends EntityService<IRoutingTree> {
  private applicationId: string;

  constructor(applicationId: string) {
    super();
    this.applicationId = applicationId;
  }

  getAll(): Promise<IRoutingTree[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.RoutingTrees);
  }

  getId(item: IRoutingTree): string {
    return item.RoutingTreeId;
  }

  getPath(): string {
    const applicationId = this.applicationId;
    return `/applications/${applicationId}/routing-trees`;
  }
}
