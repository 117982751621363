import { useEffect, useState, useMemo } from 'react';
import { GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import EntityListView from 'components/EntityListView/EntityListView';
import { useSnackBar, useDialog, useLoader } from 'context';
import IDictionaryDefinition from 'entities/DictionaryDefinition/IDictionaryDefinition';
import { DictionaryDefinitionService } from 'entities/DictionaryDefinition/DictionaryDefinitionService';
import { DictionaryService } from 'entities/Dictionary/DictionaryService';

//===============================================
// private variables
//===============================================

const inboundValueGetter = (params: GridValueGetterParams<IDictionaryDefinition>) => {
  const inbound = params.row.InboundEntryParameters;
  return `${inbound?.Inputs?.length ?? 0} / ${inbound?.Outputs?.length ?? 0}`;
};

const outboundValueGetter = (params: GridValueGetterParams<IDictionaryDefinition>) => {
  const outbound = params.row.OutboundEntryParameters;
  return `${outbound?.Inputs?.length ?? 0} / ${outbound?.Outputs?.length ?? 0}`;
};

//===============================================
// Component props interface
//===============================================

interface IDictionaryistViewProps {
  customerNodeId: string;
}

//===============================================
// Component render function
//===============================================

const DictionaryListView = (props: IDictionaryistViewProps) => {
  const [data, setData] = useState<Array<IDictionaryDefinition>>([]);
  const { customerNodeId } = props;
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listDictionaries = async () => {
    const service = new DictionaryDefinitionService(customerNodeId);

    loader(
      service
        .getAll()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (name: string) => {
    const service = new DictionaryService(customerNodeId);

    service
      .delete(name)
      .then(() => {
        showSnackBar(`${name} deleted successfully.`, 'success');
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
      .finally(() => {
        listDictionaries();
      });
  };

  const dialogDelete = (props: GridRowParams<IDictionaryDefinition>) => {
    openDialog(
      'Delete Dictionary',
      `You are about to remove '${props.row.Name}'. This action is not reversible.`,
      () => {
        handleDelete(props.row.Name);
      }
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'Name', headerName: 'Name', type: 'string', flex: 3 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 3 },
      {
        field: 'InboundEntryParameters',
        headerName: 'Inbound',
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 1,
        valueGetter: inboundValueGetter
      },
      {
        field: 'OutboundEntryParameters',
        headerName: 'Outbound',
        headerAlign: 'center',
        align: 'center',
        type: 'string',
        flex: 1,
        valueGetter: outboundValueGetter
      }
    ],
    []
  );

  useEffect(() => {
    listDictionaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      getId={(row) => row.Name}
      deleteActionHandler={dialogDelete}
      buttons={[]}
    />
  );
};

export default DictionaryListView;
