import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';

import App from './App';
import config from 'config/config';
import InvalidConfig from 'config/InvalidConfig';
import { KeycloakProvider } from 'context/Keycloak';

LicenseInfo.setLicenseKey(
  '14ca93d5d9b5fefe4659e5320e5e0be2Tz02MjE3NCxFPTE3MTA1OTUyMjA3ODgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const component = config.isValid ? (
  <KeycloakProvider>
    <App />
  </KeycloakProvider>
) : (
  <InvalidConfig />
);

root.render(component);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
