import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema } from 'yup';
import IContract from './IContract';

export default class ContractSchema extends EntitySchema<IContract> {
  override getSchema(): ISchema<IContract> {
    const schema: ObjectSchema<IContract> = object({
      ContractId: this.identifier,
      Active: this.boolean,
      ContractName: this.name,
      Description: this.description,
      ValidFrom: this.validFromDate.defined(),
      ValidTo: this.validToDate
    });

    return schema;
  }
}
