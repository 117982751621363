import { SyntheticEvent, useEffect, useReducer } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { GridColDef, GridFilterItem, GridValueGetterParams } from '@mui/x-data-grid-pro';
import EntitySelectDialog from 'components/EntitySelectDialog/EntitySelectDialog';
import useFilterModel from 'components/EntitySelectDialog/useFilterModel';
import {
  IState,
  createInitState,
  reducer,
  setOptionsAction,
  setStateAction
} from './RoutingComponentSelectDialogState';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';

//===============================================
// private variables
//===============================================

const actionsValueGetter = (params: GridValueGetterParams<IRoutingComponent>) => {
  const actions = params.row.Actions;
  return actions && actions.length > 0 ? 'Implementation' : 'Adapter';
};

const columns: GridColDef[] = [
  { field: 'RoutingComponentId', headerName: 'Routing Component ID', type: 'string', flex: 2 },
  { field: 'Service', headerName: 'Service', type: 'string', flex: 2 },
  { field: 'ImplementationVariant', headerName: 'Variant', type: 'string', flex: 1 },
  {
    field: 'Actions',
    headerName: 'Type',
    type: 'singleSelect',
    valueOptions: ['Adapter', 'Implementation'],
    flex: 2,
    valueGetter: actionsValueGetter
  },
  { field: 'Description', headerName: 'Description', type: 'string', flex: 4 }
];

//===============================================
// Component props interface
//===============================================

interface IProps {
  data: IRoutingComponent[];
  type?: null | 'Adapter' | 'Implementation';
  onClose: () => void;
  onSelect: (value: IRoutingComponent) => void;
}

//===============================================
// Component render function
//===============================================

function SelectRoutingComponentDialog(props: IProps) {
  const { data, type = null, onClose, onSelect } = props;
  const [filterModel, setFilterModelItem] = useFilterModel();
  const [state, dispatch] = useReducer(reducer, createInitState());

  useEffect(() => {
    dispatch(setOptionsAction(data));
  }, [data]);

  useEffect(() => {
    const item = {
      id: 'type',
      field: 'Actions',
      operator: 'is',
      value: type
    };
    setFilterModelItem(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const update = (item: GridFilterItem, state: IState) => {
    setFilterModelItem(item);
    dispatch(setStateAction(state));
  };

  const onServiceChange = (_event: SyntheticEvent, value: string | null) => {
    const item = {
      id: 'service',
      field: 'Service',
      operator: 'equals',
      value: value
    };
    state.service = value;
    update(item, state);
  };

  const onVariantChange = (_event: SyntheticEvent, value: string | null) => {
    const item = {
      id: 'variant',
      field: 'ImplementationVariant',
      operator: 'equals',
      value: value
    };
    state.variant = value;
    update(item, state);
  };

  const onTypeChange = (_event: SyntheticEvent, value: string | null) => {
    const item = {
      id: 'type',
      field: 'Actions',
      operator: 'is',
      value: value
    };
    state.type = value;
    update(item, state);
  };

  return (
    <EntitySelectDialog
      title='Select Routing Component'
      columns={columns}
      data={data}
      filterModel={filterModel}
      getItemId={(item: IRoutingComponent) => item.RoutingComponentId}
      initialState={{
        columns: { columnVisibilityModel: { RoutingComponentId: false } },
        sorting: {
          sortModel: [
            { field: 'Service', sort: 'asc' },
            { field: 'ImplementationVariant', sort: 'asc' }
          ]
        }
      }}
      onClose={onClose}
      onSelect={onSelect}>
      <Stack spacing={2}>
        <Autocomplete
          autoSelect
          autoHighlight
          includeInputInList
          value={state.service}
          options={state.serviceOptions}
          onChange={onServiceChange}
          renderInput={(params) => <TextField {...params} label='Service' autoFocus />}
        />

        <Autocomplete
          autoSelect
          autoHighlight
          includeInputInList
          value={state.variant ? state.variant : null}
          options={state.variantOptions}
          onChange={onVariantChange}
          renderInput={(params) => <TextField {...params} label='Variant' />}
        />

        <Autocomplete
          autoSelect
          autoHighlight
          includeInputInList
          value={state.type ? state.type : type}
          options={state.typeOptions}
          onChange={onTypeChange}
          disabled={!!type}
          renderInput={(params) => <TextField {...params} label='Type' />}
        />
      </Stack>
    </EntitySelectDialog>
  );
}

export default SelectRoutingComponentDialog;
