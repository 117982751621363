import { useParams } from 'react-router-dom';
import { createEmptySubContract } from 'entities/SubContract/SubContractUtils';
import SubContractForm from 'pages/SubContract/SubContractForm';

const SubContractCreate = () => {
  const { contractId = '' } = useParams();
  const subContract = createEmptySubContract();

  subContract.ContractId = contractId;

  return <SubContractForm subContract={subContract} />;
};

export default SubContractCreate;
