import Typography from '@mui/material/Typography';
import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

const DSDesigner = () => {
  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <AppBar position='fixed'>
        <Toolbar>
          <Typography variant='h6' noWrap component='div'>
            CCS Admin Portal Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ margin: '2rem' }} spacing={3} direction={'column'}>
        <Typography>The DS Designer application currently under construction. Please check back later.</Typography>
        <Link to='/'>
          <Button variant='contained' color='primary' sx={{ width: '100%' }}>
            Back to Dashboard
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};
export default DSDesigner;
