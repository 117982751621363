import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IDictionaryDefinition from './IDictionaryDefinition';

//==============================================================
// DictionaryDefinition EntityService
//==============================================================

export class DictionaryDefinitionService extends EntityService<IDictionaryDefinition> {
  private customerNodeId: string;

  constructor(customerNodeId: string) {
    super();
    this.customerNodeId = customerNodeId;
  }

  getAll(): Promise<IDictionaryDefinition[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.DictionaryDefinitions);
  }

  getId(item: IDictionaryDefinition): string {
    return item.Name;
  }

  getPath(): string {
    const customerNodeId = this.customerNodeId;
    return `/customer-nodes/${customerNodeId}/dictionary-definitions`;
  }

  //============================================================
  // unsupported methods
  //============================================================
  override delete(_id: string): Promise<IDictionaryDefinition> {
    // delete not supported for DictionaryDefinition
    throw new Error('Method not implemented.');
  }

  override get(_id: string): Promise<IDictionaryDefinition> {
    // get by id not supported for DictionaryDefinition
    throw new Error('Method not implemented.');
  }

  override save(_item: IDictionaryDefinition): Promise<IDictionaryDefinition> {
    // save not supported for DictionaryDefinition
    throw new Error('Method not implemented.');
  }
}
