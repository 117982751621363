import { Route, Routes } from 'react-router-dom';
import ContractList from 'pages/Contract/ContractListView';
import ContractCreate from './ContractCreate';
import ContractEdit from './ContractEdit';
import Application from 'routes/Application/Application';
import SubContract from 'routes/SubContract/SubContract';

const Contract = () => {
  return (
    <Routes>
      <Route index element={<ContractList />} />
      <Route path='create' element={<ContractCreate />} />
      <Route path=':contractId' element={<ContractEdit />} />
      <Route path=':contractId/applications/*' element={<Application />} />
      <Route path=':contractId/subcontracts/*' element={<SubContract />} />
    </Routes>
  );
};

export default Contract;
