import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { StyledLink } from './DashCardStyles';

interface IDashCardProps {
  title: string;
  icon: ReactNode;
  buttonLink: string;
  target?: string;
}

const DashCard = (props: IDashCardProps) => {
  const { target = '' } = props;

  return (
    <Box>
      <StyledLink to={props.buttonLink} target={target} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            display: 'fixed',
            flexDirection: 'none', // Align content in a column
            alignItems: 'center', // Center align items horizontally
            justifyContent: 'center', // Center align items vertically
            minWidth: 222,
            height: 222,
            borderRadius: 7,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.2)', // Added shadow layers
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2), 0px 0px 8px rgba(0, 0, 0, 0.3)' // Updated shadow on hover
            }
          }}>
          <CardContent>
            {props.icon}
            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: 'primary', textAlign: 'center' }} gutterBottom>
              {props.title}
            </Typography>
          </CardContent>
        </Card>
      </StyledLink>
    </Box>
  );
};

export default DashCard;
