import ISubContract from './ISubContract';

export const createEmptySubContract = (): ISubContract => {
  return {
    SubContractId: '',
    ContractId: '',
    SubContractName: '',
    Active: true
  };
};
