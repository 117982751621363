import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import IContract from 'entities/Contract/IContract';
import ContractSchema from 'entities/Contract/ContractSchema';
import MuiDatePicker from 'components/DateRangePicker/MuiDatePicker';
import { Typography, Stack, Box } from '@mui/material';
import { ContractService } from 'entities/Contract/ContractService';
import { useSnackBar } from 'context';
import OperationButtonGroup from 'components/OperationButtonGroup/OperationButtonGroup';
import MuiTextField from 'components/TextField/MuiTextField';
import useNavigateBack from 'hooks/useNavigateBack';

const schema = new ContractSchema().getSchema();

interface IContractForm {
  contract: IContract;
  readonly?: boolean;
}

const ContractForm = (props: IContractForm) => {
  const { contract, readonly = false } = props;
  const navigateBack = useNavigateBack();
  const { showSnackBar } = useSnackBar();
  const update = !!contract.ContractId;

  const onDateChangeHandler = (selectedDate: string, name: 'ValidTo' | 'ValidFrom') => {
    setValue(name, selectedDate, { shouldValidate: true });
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid }
  } = useForm<IContract>({
    resolver: yupResolver(schema as AnyObjectSchema),
    mode: 'onChange',
    defaultValues: contract
  });

  const onSubmit: SubmitHandler<IContract> = (contract) => {
    const action = update ? 'updated' : 'created';

    ContractService.save(contract)
      .then((result) => {
        showSnackBar(`${result.ContractId} ${action} successfully.`, 'success');
        navigateBack();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
          {update ? (readonly ? 'View' : 'Update') : 'Create'} Contract
        </Typography>

        <Stack spacing={2} sx={{ minWidth: 600 }}>
          <MuiTextField field='ContractName' control={control} label={'Name'} readonly={readonly} isRequired={true} />

          <MuiTextField field='Description' control={control} readonly={readonly} />

          <Stack spacing={2} direction={'row'}>
            <MuiDatePicker
              name={'ValidFrom'}
              control={control}
              register={register}
              minDate={''}
              maxDate={''}
              disabled={contract.ContractId ? true : false}
              onDateChange={onDateChangeHandler}
            />

            <MuiDatePicker
              name={'ValidTo'}
              control={control}
              register={register}
              minDate={''}
              maxDate={''}
              disabled={contract.ContractId ? true : false}
              onDateChange={onDateChangeHandler}
            />
          </Stack>

          <OperationButtonGroup isSaveDisabled={!isValid} readonly={readonly} />
        </Stack>
      </form>
    </Box>
  );
};

export default ContractForm;
