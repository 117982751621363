import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { dateValueFormatter } from 'utils/DataGridUtils';

export type dtapOption = { key: string; label: string };

const dtap_display_values: dtapOption[] = [
  { key: 'dev', label: 'Development' },
  { key: 'test', label: 'Test' },
  { key: 'acc', label: 'Acceptance' },
  { key: 'prod', label: 'Production' }
];

const dtapClassFormatter = (params: GridValueFormatterParams<string>) => {
  return getDtapDisplayValue(params.value);
};

const getDtapDisplayValue = (key: string | undefined): string => {
  const item = dtap_display_values.find((item) => item.key === key);
  return item ? item.label : '';
};

const columns: GridColDef[] = [
  { field: 'NodeId', headerName: 'Customer Node ID', type: 'string', flex: 2 },
  { field: 'ServicePackageId', headerName: 'Service Package ID', type: 'string', flex: 2 },
  { field: 'Active', headerName: 'Active', type: 'boolean', flex: 1 },
  { field: 'Description', headerName: 'Description', type: 'string', flex: 3 },
  { field: 'URI', headerName: 'URI', type: 'string', flex: 3 },
  {
    field: 'DTAPClass',
    headerName: 'DTAP Class',
    type: 'string',
    flex: 1,
    valueFormatter: dtapClassFormatter
  },
  {
    field: 'ValidFrom',
    headerName: 'Valid From',
    type: 'string',
    width: 150,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: dateValueFormatter
  },
  {
    field: 'ValidTo',
    headerName: 'Valid To',
    type: 'string',
    width: 150,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: dateValueFormatter
  }
];

export { dtap_display_values, getDtapDisplayValue, columns };
