import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridActionsCellItem, GridColDef, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';

import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { ApplicationService } from 'entities/Application/ApplicationService';
import { RoutingTreeInfo } from 'entities/RoutingTree/RoutingTreeInfo';
import { useSnackBar, useDialog, useLoader } from 'context';
import IApplication from 'entities/Application/IApplication';

//===============================================
// private variables
//===============================================

const typeValueGetter = (params: GridValueGetterParams<IApplication>) => {
  const hasSubContractId = !!params.row.SubContractId;
  return hasSubContractId ? 'Sub Contract' : 'Contract';
};

const parentIdValueGetter = (params: GridValueGetterParams<IApplication>) => {
  const hasSubContractId = !!params.row.SubContractId;
  return hasSubContractId ? params.row.SubContractId : params.row.ContractId;
};

//===============================================
// Component props interface
//===============================================

interface IApplicationListViewProps {
  filter: (application: IApplication) => boolean;
}

//===============================================
// Component render function
//===============================================

const ApplicationListView = (props: IApplicationListViewProps) => {
  const { filter } = props;
  const [data, setData] = useState<Array<IApplication>>([]);
  const navigate = useNavigate();
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listApplications = () => {
    loader(
      ApplicationService.getAll()
        .then((data) => {
          if (Array.isArray(data)) {
            data = data.filter(filter);
            setData(data);
          }
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    ApplicationService.delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
      .finally(() => {
        listApplications();
      });
  };

  const dialogDelete = (props: GridRowParams<IApplication>) => {
    openDialog(
      'Delete Application',
      `You are about to remove ${props.row.ApplicationName}. This action is not reversible.`,
      () => {
        handleDelete(props.row.ApplicationId ?? '');
      }
    );
  };

  const getActions = (params: GridRowParams<IApplication>) => [
    <GridActionsCellItem
      icon={<RoutingTreeInfo.Icon />}
      label={RoutingTreeInfo.displayName}
      onClick={() => navigate(`${params.id}/routing-trees`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'ApplicationId', headerName: 'Application ID', type: 'string', flex: 2 },
      { field: 'ContractId', headerName: 'Contract ID', type: 'string', flex: 2 },
      { field: 'SubContractId', headerName: 'SubContract ID', type: 'string', flex: 2 },
      { field: 'Type', headerName: 'Type', type: 'string', flex: 1, valueGetter: typeValueGetter },
      { field: 'ParentId', headerName: 'Parent ID', type: 'string', flex: 2, valueGetter: parentIdValueGetter },
      { field: 'ApplicationName', headerName: 'Application Name', type: 'string', flex: 2 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 2 }
    ],
    []
  );

  useEffect(() => {
    listApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      columnVisibilityModel={{
        ApplicationId: true,
        ContractId: false,
        SubContractId: false
      }}
      getId={(row) => row.ApplicationId}
      getActions={getActions}
      deleteActionHandler={dialogDelete}
    />
  );
};

export default ApplicationListView;
