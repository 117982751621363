import Typography from '@mui/material/Typography';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import DashCard from './DashCard';
import SettingsSuggestTwoToneIcon from '@mui/icons-material/SettingsSuggestTwoTone';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import AppBarMenu from 'components/AppBarMenu/AppBarMenu';

const DashBoard = () => {
  const ds_designer_url = '/DashBoard/DSDesigner';
  const target = '_self';

  return (
    <Box sx={{ margin: '2rem', overflowY: 'auto', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <AppBar position='fixed'>
        <Toolbar>
          <Typography variant='h6' noWrap component='div' sx={{ marginLeft: 10, textAlign: 'center', flexGrow: 1 }}>
            CCS Admin Portal Dashboard
          </Typography>
          <AppBarMenu />
        </Toolbar>
      </AppBar>
      <Stack sx={{ margin: '2rem' }} spacing={3} direction={'row'}>
        <DashCard
          title='CS UI'
          icon={<SettingsSuggestTwoToneIcon color='primary' sx={{ fontSize: 200 }} />}
          buttonLink='/contracts'
        />
        <DashCard
          title='DS Designer'
          icon={<EngineeringTwoToneIcon color='primary' sx={{ fontSize: 200 }} />}
          buttonLink={ds_designer_url}
          target={target}
        />
      </Stack>
    </Box>
  );
};
export default DashBoard;
