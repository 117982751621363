import { IDictionaryDefinitionParameters } from 'entities/DictionaryDefinition/IDictionaryDefinitionParameters';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem, GridRowId, GridRowModes } from '@mui/x-data-grid-pro';
import { IDictionaryParameter, IDictionaryParameters } from 'entities/Dictionary/IDictionaryParameters';
import IDictionary from 'entities/Dictionary/IDictionary';

export interface NewRow {
  id: number;
  [key: string]: any;
}

export const flattenParameters = (parameters: any) => {
  // Flatten the InputParameters and OutputParameters arrays
  const flattenedParameters = parameters.map((param: any) => ({
    ...param.InputParameters.reduce((acc: any, curr: any) => {
      acc[curr.Name] = curr.Value;
      return acc;
    }, {}),
    ...param.OutputParameters.reduce((acc: any, curr: any) => {
      acc[curr.Name] = curr.Value;
      return acc;
    }, {})
  }));

  return flattenedParameters;
};

export const convertToInOutboundEntryParameters = (inputArray: any[]): IDictionaryParameters[] => {
  return inputArray.map((item) => {
    const inputParameters: IDictionaryParameter[] = [];
    const outputParameters: IDictionaryParameter[] = [];

    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const value = item[key];

        if (value !== '' && value !== undefined && value !== null) {
          const parameterKey = key.substring(4);
          const parameter: IDictionaryParameter = { Name: parameterKey, Value: value };

          if (key.startsWith('000_')) {
            outputParameters.push(parameter);
          } else {
            inputParameters.push(parameter);
          }
        }
      }
    }
    return { InputParameters: inputParameters, OutputParameters: outputParameters };
  });
};

export function validateDictionary(jsonObj: any): boolean {
  if (!jsonObj) {
    return false;
  }

  const inboundEntryParameters = jsonObj.InboundEntryParameters || [];
  const outboundEntryParameters = jsonObj.OutboundEntryParameters || [];

  return (
    inboundEntryParameters.every(validateEntryParameters) && outboundEntryParameters.every(validateEntryParameters)
  );
}

export function validateEntryParameters(entry: any): boolean {
  return validateParameters(entry.InputParameters) && validateParameters(entry.OutputParameters);
}

export function validateParameters(parameters: any[]): boolean {
  return parameters && parameters.length > 0;
}

export function addPrefixIfNotPresent(name: string, prefix: string): string {
  if (name && !name.startsWith(prefix)) {
    return prefix + name;
  }
  return name;
}

export function modifyDictionaryParameterNames(jsonData: IDictionary): IDictionary {
  if (!jsonData) {
    return jsonData;
  }

  if (Array.isArray(jsonData.InboundEntryParameters)) {
    jsonData.InboundEntryParameters.forEach((entryParameter) => {
      if (entryParameter.InputParameters) {
        entryParameter.InputParameters.forEach((input) => {
          if (input.Name) {
            input.Name = addPrefixIfNotPresent(input.Name, '111_');
          }
        });
      }

      if (entryParameter.OutputParameters) {
        entryParameter.OutputParameters.forEach((output) => {
          if (output.Name) {
            output.Name = addPrefixIfNotPresent(output.Name, '000_');
          }
        });
      }
    });
  }

  if (Array.isArray(jsonData.OutboundEntryParameters)) {
    jsonData.OutboundEntryParameters.forEach((entryParameter) => {
      if (entryParameter.InputParameters) {
        entryParameter.InputParameters.forEach((input) => {
          if (input.Name) {
            input.Name = addPrefixIfNotPresent(input.Name, '111_');
          }
        });
      }

      if (entryParameter.OutputParameters) {
        entryParameter.OutputParameters.forEach((output) => {
          if (output.Name) {
            output.Name = addPrefixIfNotPresent(output.Name, '000_');
          }
        });
      }
    });
  }

  return jsonData;
}

export function generateColumns(
  entryParametersColumns: IDictionaryDefinitionParameters | undefined,
  handleSaveClick: any,
  handleCancelClick: any,
  handleEditClick: any,
  handleDeleteClick: any,
  rowModesModel: any,
  readonly: boolean
) {
  const inputColumnNames = entryParametersColumns?.Inputs?.map((input) => input.Name) ?? [];
  const outputColumnNames = entryParametersColumns?.Outputs?.map((output) => output.Name) ?? [];

  const columns = [
    ...inputColumnNames.map((name: string) => ({
      field: '111_' + name,
      headerName: name,
      headerClassName: 'inputColumn',
      flex: 2,
      editable: true
    })),
    ...outputColumnNames.map((name: string) => ({
      field: '000_' + name,
      headerName: name,
      headerClassName: 'outputColumn',
      flex: 2,
      editable: true
    })),
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'actionColumn',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: { id: GridRowId }) => {
        if (!readonly) {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label='Save'
                sx={{
                  color: 'primary.main'
                }}
                onClick={handleSaveClick(id)}
                disabled={readonly}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelClick(id)}
                color='inherit'
                disabled={readonly}
              />

            ];
          }
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
            disabled={readonly}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleDeleteClick(id)}
            color='inherit'
            disabled={readonly}
          />
        ];
      }
    }
  ];

  return columns;
}
