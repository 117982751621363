import ICacheConfiguration from './ICacheConfiguration';

export function createEmptyCacheConfiguration(): ICacheConfiguration {
  return {
    CacheConfigurationId: '',
    CacheName: '',
    Enabled: true,
    MaxMemoryOffHeap: 0,
    MaxElementsInMemory: 0,
    MaxEntriesLocalDisk: 0,
    TimeToIdle: 0,
    TimeToLive: 0
  };
}
