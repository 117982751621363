import Server from 'entities/RCIPServerProxy';
import EntityService from 'entities/EntityService';
import IContract from './IContract';
import RCIPError from 'entities/RCIPError';

export type ImportMode = 'merge' | 'override' | 'skip';

//==============================================================
// Contract EntityService
//==============================================================

class ContractService extends EntityService<IContract> {
  getAll(): Promise<IContract[]> {
    const path = this.getPath();
    return Server.get(path).then((data) => data?.Contracts);
  }

  getId(item: IContract): string {
    return item.ContractId;
  }

  getPath(): string {
    return '/contracts';
  }

  export(id: string) {
    const path = `/contracts/${id}/actions/export`;
    return Server.get(path).then((data: string) => data);
  }

  import(data: string, mode: ImportMode) {
    const path = `/contracts/actions/import?ImportMode=${mode}`;
    const json = JSON.parse(data);
    return Server.put(path, json);
  }

  override errorHandler(original: RCIPError, id: string, item: IContract | null): RCIPError {
    const error = super.errorHandler(original, id, item);

    if (item && (error.code === '2207' || error.code === '2210')) {
      error.message = `${item.ContractName} already exists.`;
    }

    return error;
  }
}

//==============================================================
// export instance
//==============================================================

const instance = new ContractService();

export { instance as ContractService };
