import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';

import { useSnackBar, useDialog, useLoader } from 'context';
import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { CustomerNodeService } from 'entities/CustomerNode/CustomerNodeService';
import { columns as column_defs } from 'entities_ui/CustomerNode/CustomerNodeColumnDefs';
import { DictionaryDefinitionInfo } from 'entities/DictionaryDefinition/DictionaryDefinitionInfo';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';

//===============================================
// Component props interface
//===============================================

interface ICustomerNodeListViewProps {
  servicePackageId: string;
}

//===============================================
// Component render function
//===============================================

const CustomerNodeListView = (props: ICustomerNodeListViewProps) => {
  const { servicePackageId } = props;
  const navigate = useNavigate();
  const [data, setData] = useState<Array<ICustomerNode>>([]);
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();
  const columns = [...column_defs];

  const listCustomerNodes = () => {
    const promise = servicePackageId
      ? CustomerNodeService.getByServicePackageId(servicePackageId)
      : CustomerNodeService.getAll();

    loader(
      promise
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    CustomerNodeService.delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
        listCustomerNodes();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const dialogDelete = (props: GridRowParams<ICustomerNode>) => {
    openDialog(
      'Delete CustomerNode',
      `You are about to remove ${props.row.NodeId}. This action is not reversible.`,
      () => {
        handleDelete(props.row.NodeId ?? '');
      }
    );
  };

  const getActions = (params: GridRowParams<ICustomerNode>) => [
    <GridActionsCellItem
      icon={<DictionaryDefinitionInfo.Icon />}
      label={DictionaryDefinitionInfo.displayName}
      onClick={() => navigate(`${params.id}/dictionaries`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />
  ];

  useEffect(() => {
    listCustomerNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      columnVisibilityModel={{
        ServicePackageId: false,
        Active: false,
        URI: false
      }}
      getId={(row) => row.NodeId}
      getActions={getActions}
      deleteActionHandler={dialogDelete}
    />
  );
};

export default CustomerNodeListView;
