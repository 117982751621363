import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnackBar } from 'context';
import { createEmptyDictionary } from 'entities/Dictionary/DictionaryUtils';
import { createEmptyDictionaryDefinition } from 'entities/DictionaryDefinition/DictionaryDefinitionUtils';
import { DictionaryService } from 'entities/Dictionary/DictionaryService';
import { DictionaryDefinitionService } from 'entities/DictionaryDefinition/DictionaryDefinitionService';
import DictionaryForm from 'pages/Dictionary/DictionaryForm';
import IDictionary from 'entities/Dictionary/IDictionary';
import IDictionaryDefinition from 'entities/DictionaryDefinition/IDictionaryDefinition';

const DictionaryEdit = () => {
  const { customerNodeId = '', dictionaryName = '' } = useParams();
  const [searchParams] = useSearchParams();
  const [dictionary, setDictionary] = useState<IDictionary>(createEmptyDictionary());
  const [dictionaryDefinition, setDictionaryDefinition] = useState<IDictionaryDefinition>(
    createEmptyDictionaryDefinition()
  );
  const readonly = searchParams.get('readonly') === '' ?? false;

  const fetchedData = !!dictionary.Name && !!dictionaryDefinition.Name;
  const { showSnackBar } = useSnackBar();

  useEffect(() => {
    Promise.all([
      new DictionaryService(customerNodeId).get(dictionaryName),
      new DictionaryDefinitionService(customerNodeId).getAll()
    ])
      .then((data) => {
        const definition = data[1].find((definition) => definition.Name === dictionaryName);

        setDictionary(data[0]);

        if (definition) {
          setDictionaryDefinition(definition);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNodeId, dictionaryName]);

  return fetchedData ? (
    <DictionaryForm
      customerNodeId={customerNodeId}
      dictionary={dictionary}
      definition={dictionaryDefinition}
      readonly={readonly}
    />
  ) : (
    <React.Fragment />
  );
};

export default DictionaryEdit;
