import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackBar, useDialog, useLoader } from 'context';
import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { ServicePackageInfo } from 'entities/ServicePackage/ServicePackageInfo';
import { SubContractService } from 'entities/SubContract/SubContractService';
import { ApplicationInfo } from 'entities/Application/ApplicationInfo';
import { dateValueFormatter } from 'utils/DataGridUtils';
import ISubContract from 'entities/SubContract/ISubContract';
import { CacheConfigurationInfo } from 'entities/CacheConfiguration/CacheConfigurationInfo';

//===============================================
// Component props interface
//===============================================

interface ISubContractListViewProps {
  contractId: string;
}

//===============================================
// Component render function
//===============================================

const SubContractListView = (props: ISubContractListViewProps) => {
  const { contractId } = props;
  const [data, setData] = useState<Array<ISubContract>>([]);
  const navigate = useNavigate();
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listSubContracts = () => {
    const promise = contractId ? SubContractService.getByContractId(contractId) : SubContractService.getAll();

    loader(
      promise
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    SubContractService.delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
        listSubContracts();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const dialogDelete = (props: GridRowParams<ISubContract>) => {
    openDialog(
      'Delete SubContract',
      `You are about to remove ${props.row.SubContractName}. This action is not reversible.`,
      () => {
        handleDelete(props.row.SubContractId ?? '');
      }
    );
  };

  const getActions = (params: GridRowParams<ISubContract>) => [
    <GridActionsCellItem
      icon={<ApplicationInfo.Icon />}
      label={ApplicationInfo.displayName}
      onClick={() => navigate(`${params.id}/applications`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />,
    <GridActionsCellItem
      icon={<CacheConfigurationInfo.Icon />}
      label={CacheConfigurationInfo.displayName}
      onClick={() => navigate(`${params.id}/cache-configurations`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />,
    <GridActionsCellItem
      icon={<ServicePackageInfo.Icon />}
      label={ServicePackageInfo.displayName}
      onClick={() => navigate(`${params.id}/service-packages`)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'SubContractId', headerName: 'Sub Contract ID', type: 'string', flex: 2 },
      { field: 'ContractId', headerName: 'Contract ID', type: 'string', flex: 2 },
      { field: 'SubContractName', headerName: 'Sub Contract Name', type: 'string', flex: 2 },
      { field: 'Active', headerName: 'Active', type: 'boolean', flex: 1 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 3 },
      {
        field: 'ValidFrom',
        headerName: 'Valid From',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      },
      {
        field: 'ValidTo',
        headerName: 'Valid To',
        type: 'string',
        width: 150,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: dateValueFormatter
      }
    ],
    []
  );

  useEffect(() => {
    listSubContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      columnVisibilityModel={{
        SubContractId: false,
        Active: false
      }}
      getId={(row) => row.SubContractId}
      getActions={getActions}
      deleteActionHandler={dialogDelete}
    />
  );
};
export default SubContractListView;
