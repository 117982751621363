import { ReactNode, createContext, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface DialogContextProps {
  openDialog: (newTitle: string, newContent: string, callback: () => void) => void;
  closeDialog: () => void;
}

// Create a new context for the dialog
const DialogContext = createContext<DialogContextProps>({
  openDialog: () => {},
  closeDialog: () => {}
});

// Custom hook to access the dialog context
const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

// Dialog Provider component
const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [confirmCallback, setConfirmCallback] = useState<(() => void) | null>(null);

  const openDialog = (newTitle: string, newContent: string, callback: () => void) => {
    setTitle(newTitle);
    setContent(newContent);
    setIsOpen(true);
    setConfirmCallback(() => callback);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    if (confirmCallback) {
      confirmCallback();
    }
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()} color='primary' variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};

export { DialogProvider, useDialog };
