import { SyntheticEvent, useState } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import EntitySelectDialog from 'components/EntitySelectDialog/EntitySelectDialog';
import useFilterModel from 'components/EntitySelectDialog/useFilterModel';
import { dtap_display_values, columns, dtapOption } from './CustomerNodeColumnDefs';
import ICustomerNode from 'entities/CustomerNode/ICustomerNode';

//===============================================
// Component props interface
//===============================================

interface IProps {
  data: ICustomerNode[];
  onClose: () => void;
  onSelect: (value: ICustomerNode) => void;
}

//===============================================
// Component render function
//===============================================

function CustomerNodeSelectDialog(props: IProps) {
  const { data, onClose, onSelect } = props;
  const [filterModel, setFilterModelItem] = useFilterModel();
  const [dtap, setDtap] = useState<dtapOption | null>();
  const [description, setDescription] = useState('');
  const [uri, setUri] = useState('');

  const onDtapChange = (_event: SyntheticEvent, value: { key: string; label: string } | null) => {
    const item = {
      id: 'dtap',
      field: 'DTAPClass',
      operator: 'equals',
      value: value ? value.key : null
    };

    setDtap(value);
    setFilterModelItem(item);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const item = {
      id: 'description',
      field: 'Description',
      operator: 'contains',
      value: value
    };
    setDescription(value);
    setFilterModelItem(item);
  };

  const onUriChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const item = {
      id: 'uri',
      field: 'URI',
      operator: 'contains',
      value: value
    };
    setUri(value);
    setFilterModelItem(item);
  };

  return (
    <EntitySelectDialog
      title='Select Customer Node'
      columns={columns}
      data={data}
      filterModel={filterModel}
      initialState={{
        columns: { columnVisibilityModel: { NodeId: false, ServicePackageId: false, Active: false } },
        sorting: {
          sortModel: [{ field: 'Desciption', sort: 'asc' }]
        }
      }}
      getItemId={(item: ICustomerNode) => item.NodeId}
      onClose={onClose}
      onSelect={onSelect}>
      <Stack spacing={2}>
        <Autocomplete
          autoFocus
          autoSelect
          autoHighlight
          includeInputInList
          value={dtap ? dtap : null}
          options={dtap_display_values}
          onChange={onDtapChange}
          renderInput={(params) => <TextField {...params} label='DTAP' autoFocus />}
        />

        <TextField label='Description' value={description} onChange={onDescriptionChange} />
        <TextField label='URI' value={uri} onChange={onUriChange} />
      </Stack>
    </EntitySelectDialog>
  );
}

export default CustomerNodeSelectDialog;
