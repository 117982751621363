import { useParams } from 'react-router-dom';
import { guid } from 'entities/EntitySchema';
import CacheConfigurationEdit from './CacheConfigurationEdit';
import CacheConfigurationCreateFromDefault from './CacheConfigurationCreateFromDefault';

const CacheConfigurationEditOrCreate = () => {
  const { cacheConfigurationId = '' } = useParams();
  const valid_guid = guid.isValidSync(cacheConfigurationId);

  return valid_guid ? <CacheConfigurationEdit /> : <CacheConfigurationCreateFromDefault />;
};

export default CacheConfigurationEditOrCreate;
