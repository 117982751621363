import { useFormContext, useFieldArray, UseFieldArrayReturn, FieldValues } from 'react-hook-form';
import { useStateContext } from 'pages/RoutingTree/StateContext';
import { getServiceName, getActionsName, getNodeName, getInstanceName } from '../nameUtil';
import { DetailType } from './DetailType';
import IRoutingComponent from 'entities/RoutingComponent/IRoutingComponent';
import IRoutingComponentAction from 'entities/RoutingComponent/IRoutingComponentAction';
import IRoutingTreeAction from 'entities/RoutingTree/IRoutingTreeAction';
import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';

const getAction = (routingComponent: IRoutingComponent | undefined, name: string, actionId?: string) => {
  if (routingComponent && actionId) {
    return routingComponent.Actions?.find((item) => item.ActionId === actionId);
  }

  if (routingComponent) {
    const service = getServiceName(name);
    return routingComponent.Actions?.find((item) => item.Service === service);
  }

  return undefined;
};

const getType = (
  name: string,
  instance: IRoutingTreeAction | undefined,
  routingComponent: IRoutingComponent | undefined
) => {
  if (name === 'RootNode') {
    return DetailType.RootNode;
  }

  if (!instance) {
    return DetailType.NotSet;
  }

  if (routingComponent) {
    return routingComponent.Actions ? DetailType.Implementation : DetailType.Adapter;
  }

  return DetailType.Unknown;
};

export interface IDetails {
  type: DetailType;
  name: string;
  action: IRoutingComponentAction | undefined;
  instance: IRoutingTreeAction | undefined;
  routingComponent: IRoutingComponent | undefined;
  array: UseFieldArrayReturn<FieldValues, string, 'id'>;
}

const useDetails = (name: string): IDetails => {
  const { getValues } = useFormContext();
  const { routingComponents } = useStateContext();

  const getRoutingComponent = (id: string) => routingComponents.find((item) => item.RoutingComponentId === id);

  // parent object
  const parentName = getNodeName(name);
  const parent = getValues(parentName) as IRoutingTreeNode;

  // actions array
  // WARNING: for some reasone the array.fields property doesn't seem to belong
  //          to the current array, but it seems to work as expected ...???
  // TODO: investigate this
  const arrayName = getActionsName(name);
  const array = useFieldArray({ name: arrayName });

  // action instance
  const instanceName = getInstanceName(name);
  const instance = (instanceName && getValues(instanceName)) as IRoutingTreeAction | undefined;

  // action definition
  const routingComponentParent = getRoutingComponent(parent.RoutingComponentId);
  const action = getAction(routingComponentParent, name, instance?.ActionId);

  // type of details
  const routingComponentInstance = instance && getRoutingComponent(instance.Node.RoutingComponentId);
  const type = getType(name, instance, routingComponentInstance);

  // routingComponent for current node
  const routingComponent = type === DetailType.RootNode ? routingComponentParent : routingComponentInstance;

  return {
    type,
    name,
    routingComponent,
    action,
    instance,
    array
  };
};

export default useDetails;
