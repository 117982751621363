import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackBar, useDialog, useLoader } from 'context';
import EntityListView from 'components/EntityListView/EntityListView';
import { canPerformOperation } from 'api/OperationSupport';
import { CustomerNodeInfo } from 'entities/CustomerNode/CustomerNodeInfo';
import { ServicePackageService } from 'entities/ServicePackage/ServicePackageService';
import IServicePackage from 'entities/ServicePackage/IServicePackage';

//===============================================
// Component props interface
//===============================================

interface IServicePackageListViewProps {
  subContractId: string;
}

const ServicePackageListView = (props: IServicePackageListViewProps) => {
  const { subContractId } = props;
  const [data, setData] = useState<Array<IServicePackage>>([]);
  const navigate = useNavigate();
  const { loader } = useLoader();
  const { showSnackBar } = useSnackBar();
  const { openDialog } = useDialog();

  const listServicePackages = () => {
    const promise = subContractId
      ? ServicePackageService.getBySubContractId(subContractId)
      : ServicePackageService.getAll();

    loader(
      promise
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    );
  };

  const handleDelete = (id: string) => {
    ServicePackageService.delete(id)
      .then(() => {
        showSnackBar(`${id} deleted successfully.`, 'success');
        listServicePackages();
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  };

  const dialogDelete = (props: GridRowParams<IServicePackage>) => {
    openDialog(
      'Delete  Service Package',
      `You are about to remove ${props.row.ServiceTypeCode}. This action is not reversible.`,
      () => {
        handleDelete(props.row.ServicePackageId ?? '');
      }
    );
  };

  const navigateCustomerNode = (servicePackageId: string): void => {
    return navigate(`${servicePackageId}/customer-nodes`);
  };

  const getActions = (params: GridRowParams<IServicePackage>) => [
    <GridActionsCellItem
      icon={<CustomerNodeInfo.Icon />}
      label={CustomerNodeInfo.displayName}
      onClick={() => navigateCustomerNode(params.row.ServicePackageId)}
      showInMenu
      disabled={!canPerformOperation('view')}
    />
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'ServicePackageId', headerName: 'Service Package ID', type: 'string', flex: 2 },
      { field: 'SubContractId', headerName: 'Sub Contract ID', type: 'string', flex: 2 },
      { field: 'Description', headerName: 'Description', type: 'string', flex: 2 },
      { field: 'ServiceTypeCode', headerName: 'Service Type', type: 'string', flex: 2 }
    ],
    []
  );

  useEffect(() => {
    listServicePackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EntityListView
      columns={columns}
      rows={data}
      getId={(row) => row.ServicePackageId}
      getActions={getActions}
      deleteActionHandler={dialogDelete}
    />
  );
};

export default ServicePackageListView;
