import { Route, Routes } from 'react-router-dom';
import ServicePackageList from './ServicePackageList';
import ServicePackageCreate from './ServicePackageCreate';
import ServicePackageEdit from './ServicePackageEdit';
import CustomerNode from 'routes/CustomerNode/CustomerNode';

const ServicePackage = () => {
  return (
    <Routes>
      <Route index element={<ServicePackageList />} />
      <Route path='create' element={<ServicePackageCreate />} />
      <Route path=':servicePackageId' element={<ServicePackageEdit />} />
      <Route path=':servicePackageId/customer-nodes/*' element={<CustomerNode />} />
    </Routes>
  );
};

export default ServicePackage;
