import IServicePackage from './IServicePackage';

export const createEmptyServicePackage = (): IServicePackage => {
  return {
    ServicePackageId: '',
    SubContractId: '',
    ServiceTypeCode: '',
    Description: ''
  };
};
