import IEntityService from './IEntityService';
import Server from 'entities/RCIPServerProxy';
import RCIPError from './RCIPError';

export default abstract class EntityService<T> implements IEntityService<T> {
  delete(id: string): Promise<T> {
    const path = this.getPath();
    return Server.delete(`${path}/${id}`).catch((error) => {
      throw this.errorHandler(error, id, null);
    });
  }

  get(id: string): Promise<T> {
    const path = this.getPath();
    return Server.get(`${path}/${id}`);
  }

  abstract getAll(): Promise<T[]>;
  abstract getId(item: T): string;
  abstract getPath(): string;

  getOutbound<T>(item: T): Partial<T> {
    const outbound:Partial<T>={...item};
    
    for (const prop in outbound) {
      if (outbound[prop] === '') {
        delete outbound[prop];
      }
    }

    return outbound;
  }

  save(item: T): Promise<T> {
    const id = this.getId(item);
    const path = this.getPath();
    const outbound = this.getOutbound(item); 
    const update = !!id;

    return (update ? Server.put(`${path}/${id}`, outbound) : Server.post(path, outbound)).catch((error) => {
      throw this.errorHandler(error, id, item);
    });
  }

  errorHandler(error: RCIPError, id: string, _item: T | null): RCIPError {
    if (error.code === '2209' && id) {
      error.message = `${id} cannot be deleted. Child records found.`;
    }
    
    return error;
  }
}
