import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useStateContext } from './StateContext';
import { TextField, Typography, Stack, Autocomplete } from '@mui/material';
import MuiDatePicker from 'components/DateRangePicker/MuiDatePicker';
import IMatchingType from 'entities/MatchingType/IMatchingType';
import IRoutingTree from 'entities/RoutingTree/IRoutingTree';
import { useState } from 'react';
import MuiTextField from 'components/TextField/MuiTextField';

interface IProps {
  routingTree: IRoutingTree;
  readonly: boolean;
}

const RoutingTreeDetails = (props: IProps) => {
  const form = useFormContext<IRoutingTree>();
  const array = useFieldArray({ name: 'Properties' });
  const state = useStateContext();
  const { routingTree, readonly } = props;
  const { matchingTypes } = state;
  const [showScope, setShowScope] = useState(routingTree.MatchingType === 'scope-based-matching');
  const update = !!routingTree.RoutingTreeId;

  const {
    register,
    control,
    setValue,
    formState: { errors }
  } = form;

  const onDateChangeHandler = (selectedDate: string, name: 'ValidTo' | 'ValidFrom') => {
    setValue(name, selectedDate, { shouldValidate: true });
  };

  const onMatchingTypeChangeHandler = (matchingType: IMatchingType | null) => {
    const code = matchingType?.Code;

    if (code === 'scope-based-matching') {
      const property = {
        Key: 'routingScope',
        Value: ''
      };
      setShowScope(true);
      array.append(property);
    } else {
      setShowScope(false);
      array.remove();
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: 24, fontWeight: 600, textAlign: 'center' }}>
        {routingTree.RoutingTreeId ?  (readonly ? 'View' : 'Update') : 'Create'} RoutingTree
      </Typography>

      <Stack spacing={2} sx={{ minWidth: 600 }}>
        <MuiTextField field='Description' control={control} readonly={readonly} />

        <Controller
          name='MatchingType'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Autocomplete
              id='MatchingType'
              readOnly={readonly}
              clearIcon={false}
              autoSelect
              autoHighlight
              includeInputInList
              options={matchingTypes}
              getOptionLabel={(option: IMatchingType) => option.Code}
              value={matchingTypes.find((option: IMatchingType) => option.Code === value) || null}
              onChange={(_event: React.SyntheticEvent, newValue: IMatchingType | null) => {
                onChange(newValue?.Code);
                onMatchingTypeChangeHandler(newValue);
              }}
              renderInput={(params: any) => (
                <TextField {...params} label='MatchingType *' error={!!error} helperText={error?.message} />
              )}
            />
          )}
        />

        {showScope && (
          <TextField
            required
            defaultValue={routingTree.Properties ? routingTree.Properties[0]?.Value : ''}
            InputProps={{ inputProps: { readOnly: readonly } }}
            multiline
            label='Routing Scope'
            fullWidth
            margin='dense'
            {...register('Properties.0.Value')}
            error={errors.Properties ? true : false}
            helperText={errors.Properties && errors.Properties[0] ? errors.Properties[0].Value?.message : ''}
          />
        )}

        <Stack spacing={2} direction={'row'}>
          <MuiDatePicker
            name={'ValidFrom'}
            control={control}
            register={register}
            minDate={''}
            maxDate={''}
            disabled={update}
            onDateChange={onDateChangeHandler}
          />

          <MuiDatePicker
            name={'ValidTo'}
            control={control}
            register={register}
            minDate={''}
            maxDate={''}
            disabled={update}
            onDateChange={onDateChangeHandler}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default RoutingTreeDetails;
