import EntitySchema from 'entities/EntitySchema';
import { object, ObjectSchema, ISchema, string } from 'yup';
import ICacheConfiguration from './ICacheConfiguration';

export default class CacheConfigurationSchema extends EntitySchema<ICacheConfiguration> {
  override getSchema(): ISchema<ICacheConfiguration> {
    const schema: ObjectSchema<ICacheConfiguration> = object({
      CacheConfigurationId: this.identifier,
      CacheName: string().defined().min(1).max(255),
      Enabled: this.boolean,
      MaxMemoryOffHeap: this.integer,
      MaxElementsInMemory: this.integer,
      MaxEntriesLocalDisk: this.integer,
      TimeToIdle: this.integer,
      TimeToLive: this.integer
    });

    return schema;
  }
}
