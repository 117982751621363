import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';

import IRoutingTreeNode from 'entities/RoutingTree/IRoutingTreeNode';
import Node from './Node';

//===============================================
// Component props interface
//===============================================

interface IProps {
  activeNode: string;
  routingTreeNode: IRoutingTreeNode;
  selectNode: (name: string) => void;
}

//===============================================
// Component render function
//===============================================

function TreeViewPanel(props: IProps) {
  const { activeNode, routingTreeNode, selectNode } = props;
  const name = 'RootNode';

  const onNodeSelect = (_event: React.SyntheticEvent, nodeId: string) => {
    selectNode(nodeId);
  };

  return (
    <Stack height='100%'>
      <TreeView
        sx={{ width: 300, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={[name]}
        selected={activeNode}
        onNodeSelect={onNodeSelect}>
        <TreeItem key={name} nodeId={name} label={name}>
          <Node node={routingTreeNode} name={name} />
        </TreeItem>
      </TreeView>
    </Stack>
  );
}

export default TreeViewPanel;
